<template>
    <div class="question_box">
        <i class="fas fa-times" @click="questionExit"></i>
        <h3 class="question_box_title">1:1 문의</h3>
        <div class="question_user_box">
            <span class="question_user_name">문의자: <font style="color: #a22727;">{{ questionData.name }}</font></span>
            <span class="question_user_solution">부서/직급: <font style="color: #a22727;">{{ questionData.deptname }} / {{
                    questionData.position
                    }}</font></span>
            <span>연락처: <font style="color: #a22727;">{{ questionData.contactno }}</font></span>
            <span>Email: <font style="color: #a22727;">{{ questionData.email }}</font></span>
        </div>
        <div class="question_in_box">
            <h4>문의 내용</h4>
            <small>문의 날짜: {{ questionData.questiondt || '' }}</small>
            <pre class="question_text">{{ questionData.question }}</pre>
        </div>
        <div class="question_in_box" v-if="questionData.answer">
            <h4>답변 내용</h4>
            <small>답변 날짜: {{ questionData.answerdt || '' }}</small>
            <pre class="question_text">{{ questionData.answer }}</pre>
        </div>
        <textarea class="questionData_text" v-model="questionText"
            v-if="!questionData.answer || putQuestion "></textarea>
        <button class="question_btn mb-0 text-sm btn btn-dark btn-sm" @click="qnestionSucess"
            v-if="!questionData.answer && this.$store.state.loginUser.authority === 9 ">답변하기</button>
        <button class="question_btn mb-0 text-sm btn btn-dark btn-sm" @click="qnestionPut"
            v-if="questionData.answer && this.$store.state.loginUser.authority === 9">{{
            putQuestion? '완료' : '수정하기' }}</button>
    </div>
</template>

<script>
import { fetchQnA, getQnADetail, putQnA, getQnA } from '../../api';

export default {
    props: {
        qnaIndex: {
            type: String,
        },
    },
    data() {
        return {
            questionData: [],
            questionText: '',
            putQuestion: false
        }
    },
    created () {
        const userData = {
            token: this.$store.state.token,
            seq: this.qnaIndex,
        };

        getQnADetail(userData)
            .then(response => {
                this.questionData = response.data;
                this.questionText = response.data.answer;
            })
            .catch(err => {
                console.log(err);
            })
    },
    methods: {
        getQnA() {
            const userData = {
                "token": this.$store.state.token,
            };

            getQnA(userData)
                .then(response => {
                    this.qnaData = response.data;
                    this.$store.state.qnaData = response.data;
                })
                .catch(err => {
                    console.log(err);
                });
        },
        qnestionSucess() {
            const userData = {
                token: this.$store.state.token,
                seq: this.qnaIndex,
                answer: this.questionText,
                
            }

            putQnA(userData)
                .then(response => {
                    alert(response.data.resultmsg);
                    this.getQnA();

                    this.$emit('qnaexit');
                })
                .catch(err => {
                    console.log(err);
                })
        },
        qnestionPut() {
            if (this.putQuestion) {
                const putQuestion = confirm('답변을 수정하시겠습니까 ?');

                if (putQuestion) {
                    const userData = {
                        token: this.$store.state.token,
                        seq: this.qnaIndex,
                        answer: this.questionText,
                    }

                    fetchQnA(userData)
                        .then(response => {
                            alert(response.data.resultmsg);

                            this.$emit('qnaexit');
                        })
                        .catch(err => {
                            console.log(err);
                        })
                }


                this.putQuestion = false;
            } else {
                this.putQuestion = true;
            }
        },
        questionExit() {
            this.$emit('qnaexit');
        }
    },
}
</script>

<style scoped>




.fa-times {
    color: black;
    float: right;
    font-size: 2.5rem;
    cursor: pointer;
}
.question_box {
    width: 70%;
    height: 70vh;
    position: fixed;
    left: 50%;
    top: 50%;
    overflow-y: scroll;
    padding: 25px 30px;
    margin-top: calc(-70vh / 2);
    margin-left: calc(-70% / 2);
    border: 1px solid lightgray;
    border-radius: 20px;
    box-shadow: 0px 10px 20px 0px lightgrey;
    background-color: white;
    z-index: 9999;
}

.question_user_box {
    display: flex;
    margin-bottom: 25px;
}

.question_user_box span::after {
    content: ' | ';
    margin: 0 20px;
}

.question_user_box span:last-child::after {
    display: none;
}

.question_text {
    font-family: 'Open Sans';
}

.question_in_box {
    margin-bottom: 50px;
    padding-top: 30px;
    border-top: 1px solid lightgray;
}

.question_in_box small {
    display: block;
    margin-bottom: 20px;
}

/* .question_btn {
} */

.questionData_text {
    width: 100%;
    height: 30%;
    resize: none;
    margin-bottom: 30px;
    display: block;
}
</style>