<template>
    <div class="card mb-4">
        <div class="card-header pb-0">
            <h6>1:1문의 관리</h6>
        </div>
        <div class="card-body px-0 pt-0 pb-2">
            <div class="table-responsive p-0">
                <table class="table align-items-center justify-content-center mb-0">
                    <thead>
                        <tr>
                            <th class="text-uppercase text-secondary text-xxs font-weight-bolder text-center opacity-7">
                                No
                            </th>
                            <th
                                class="text-uppercase text-secondary text-xxs font-weight-bolder text-center opacity-7 ps-2">
                                문의자
                            </th>
                            <th
                                class="text-uppercase text-secondary text-xxs font-weight-bolder text-center opacity-7 ps-2 ">
                                부서/직급
                            </th>
                            <th
                                class="text-uppercase text-secondary text-xxs font-weight-bolder text-center opacity-7 ps-2">
                                문의 내용
                            </th>
                            <th
                                class="text-uppercase text-secondary text-xxs font-weight-bolder text-center opacity-7 ps-2">
                                답변 상황
                            </th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="qna in qnaData" :key="qna">
                            <td class="text-center">
                                <div class="px-2">
                                    <div class="my-auto">
                                        <h6 class="mb-0 text-sm">{{ qna.rownum }}</h6>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <p class="text-sm font-weight-bold mb-0 text-center">{{ qna.name }}</p>
                            </td>
                            <td class="text-center">
                                <span class="mb-0 text-sm">
                                    {{ qna.deptnm }}({{ qna.position }})
                                </span>
                            </td>
                            <td class="text-center">
                                <span v-if="qna.question.length >= 30">
                                    {{ qna.question.slice(0, 30) }} ···
                                </span>
                                <span v-else>
                                    {{ qna.question }}
                                </span>
                            </td>
                            <td class="text-center">
                                <span class="badge bg-success" v-if="qna.answerflag === 'Y'">
                                    완료
                                </span>
                                <span class="badge bg-danger" v-else>
                                    미완료
                                </span>
                            </td>
                            <td class="text-center">
                                <button class="mb-0 text-sm btn btn-dark btn-sm" @click="qnaClick(qna.seq)">상세
                                    보기</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <transition name="fade">
                    <QuestionDetail v-if="qna" :qnaIndex="qnaIndex" @qnaexit="qnaExit"></QuestionDetail>
                </transition>
            </div>
        </div>
    </div>
</template>

<script>
import { getQnA, getProfile } from '../../api/index';
import QuestionDetail from './QuestionDetail.vue';

export default {
    data() {
        return {
            qnaData: [],
            qna: false,
            qnaIndex: ''
        };
    },
    created() {
        const userData = {
            "token": this.$store.state.token,
        };
        
        getQnA(userData)
            .then(response => {
                this.qnaData = response.data;
                this.$store.state.qnaData = response.data;
        })
            .catch(err => {
            console.log(err);
        });

        const profileData = {
            "email": this.$store.state.userEmail,
            "token": this.$store.state.token,
        }

        getProfile(profileData)
            .then(response => {
                this.$store.state.myprofile = response.data;
            })
            .catch(err => {
                console.log(err);
            });
    },
    methods: {
        qnaClick(qnaData) {
            if (this.qna) {
                this.qna = false;
            }
            else {
                this.qnaIndex = qnaData;
                this.qna = true;
            }
        },
        qnaExit() {
            if (this.qna) {
                this.getQnA();
                this.qna = false;
            }
            else {
                this.qna = true;
            }
        },
        getQnA() {
            const userData = {
                "token": this.$store.state.token,
            };

            getQnA(userData)
                .then(response => {
                    this.qnaData = response.data;
                    this.$store.state.qnaData = response.data;
                })
                .catch(err => {
                    console.log(err);
                });
        }
    },
    components: { QuestionDetail }
};
</script>

<style scoped>

.fade-enter-active,
.fade-leave-active {
    transition: opacity .3s;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active below version 2.1.8 */
    {
    opacity: 0;
}
</style>
