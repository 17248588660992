<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <QuestionsTable></QuestionsTable>
      </div>
    </div>
  </div>
</template>

<script>
import QuestionsTable from '@/views/components/QuestionsTable.vue';

export default {
  name: "questions",
  components: {
    QuestionsTable,
  },
};
</script>
