<template>
  <div style="min-width: 850px;">
    <div class="card mb-4">
      <div class="card-header pb-0 d-flex" style="justify-content: space-between;">
        <h6>기관 관리</h6>
        <div class="card-top-menu" style="display: flex;">
          <select class="pagesize_opt" style="margin-right: 10px;" v-model="pagesize" @change="serachOptBtn">
            <option value="10">10개 보기</option>
            <option value="20" selected>20개 보기</option>
            <option value="30">30개 보기</option>
          </select>
          <div class="input-group">
            <input type="text" class="form-control" placeholder="기관 검색" v-model="serachkey" @keyup.enter="serachBtn">
            <span class="input-group-text text-body">
              <i class="fas fa-search" aria-hidden="true" style="cursor: pointer;" @click="serachBtn"></i>
            </span>
          </div>
        </div>
      </div>
      <div class="card-body px-0 pt-0 pb-2">
        <div class="table-responsive p-0">
          <table class="table align-items-center mb-0">
            <thead>
              <tr>
                <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7" width="8%">
                  No
                </th>
                <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2" width="25%">
                  업체명
                </th>
                <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7" width="10%">
                  부서/직급
                </th>
                <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7" width="5%">
                  담당자
                </th>
                <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7" width="20%">
                  승인 여부
                </th>
                <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                  편집
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="user in this.$store.state.user" :key="user">
                <td>
                  <div class="d-flex px-3 py-1">
                    <div class="d-flex flex-column justify-content-center">
                      <h6 class="mb-0 text-sm">{{ user.rownum }}</h6>
                    </div>
                  </div>
                </td>
                <td>
                  <p class="text-xs font-weight-bold mb-0">{{ user.name }}</p>
                </td>
                <td class="align-middle text-center text-sm">
                  <p class="text-xs font-weight-bold mb-0">{{ `${user.depart_name} (${user.position})` }}</p>
                </td>
                <td class="align-middle text-center">
                  <span class="text-secondary text-xs font-weight-bold"
                    :title="'Email: ymh0951@naver.com\nTel: 010-9419-9543'">{{ user.worker_name }}</span>
                </td>
                <td class="align-middle text-center">
                  <span v-if="user.appstate === 'S'">대기</span>
                  <span v-else-if="user.appstate === 'A'">승인</span>
                  <span v-else-if="user.appstate === 'R'">거절</span>
                  <span v-else>중단</span>

                  <div class="appstate_btn" v-if="this.$store.state.loginUser.authority === 9 && user.appstate === 'S'">
                    <button class="btn btn-success" @click="fetchUserStateA(user)">승인</button>
                    <button class="btn btn-danger" @click="fetchUserStateR(user)">거절</button>
                  </div>
                  <div class=" appstate_btn"
                    v-if="this.$store.state.loginUser.authority === 9 && user.appstate === 'A'">
                    <button class="btn btn-secondary" @click="fetchUserStateC(user)">중단</button>
                  </div>
                </td>
                <td class="align-middle text-center">
                  <a href="javascript:;" class="text-secondary font-weight-bold text-xs" data-toggle="tooltip"
                    data-original-title="Edit user"><i class="fa fas fa-search"
                      @click="detailsClick(user.appcode)"></i></a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <transition name="fade">
          <Details v-if="!details" v-on:exit="detailsClick" :appcode="appcode"></Details>
        </transition>
      </div>
    </div>
    <div class="btn-group mr-2" role="group" aria-label="First group" style="float: right;" ref="userList" v-if="pageArr.length !== 1">
      <button type="button" class="btn btn-secondary" v-for="btn in pageArr" :key="btn" @click="changeList(btn)"
        :class="page === btn ? 'btn-info' : ''">{{ btn }}</button>
    </div>
  </div>
</template>

<script>
import Details from '@/views/components/Details.vue';
import img1 from "../../assets/img/team-2.jpg";
import img2 from "../../assets/img/team-3.jpg";
import img3 from "../../assets/img/team-4.jpg";
import img4 from "../../assets/img/team-3.jpg";
import img5 from "../../assets/img/team-2.jpg";
import img6 from "../../assets/img/team-4.jpg";
import { getUser, petchUserState } from '../../api/index';

export default {
  name: "authors-table",
  data() {
    return {
      img1,
      img2,
      img3,
      img4,
      img5,
      img6,
      details: true,
      appcode: '',
      serachkey: '',
      pagesize: "20",
      page: 1,
      pageArr: [],
    };
  },
  components: {
    Details
  },
  mounted () {
    setTimeout(() => {
      this.pageLength();
    }, 500);
  },
  methods: {
    pageLength() {
      const maxUser = this.$store.state.user[0] ? this.$store.state.user[0].totalcnt : '1';
        const pageSize = Number(this.pagesize);
  
        const listVal = maxUser / pageSize;
  
        this.pageArr = [];
        for (let i = 1; i <= Math.ceil(listVal); i++) {
          this.pageArr.push(i);
        }
    },
    changeList(value) {
      this.page = value;

      this.serachBtn();
    },
    detailsClick(appcode) {
      if (this.details) {
        this.appcode = appcode;
        this.details = false;
      } else {
        const userData = {
          token: this.$store.state.token,
          page: this.page,
        };

        getUser(userData)
          .then(response => {
            this.$store.state.user = response.data;
          })
          .catch(err => {
            console.log(err);
          });

        this.details = true;
      }
    },
    fetchUserStateA(data) {
      const a = confirm('정말 승인하시겠습니까 ?');
      const userData = {
        token: this.$store.state.token,
        appcode: data.appcode,
        chgcode: 'A',
      }
      
      if (a) {
        petchUserState(userData)
          .then(response => {
            alert(response.data.resultmsg);

            getUser(userData)
              .then(response => {
                this.$store.state.user = response.data;
              })
              .catch(err => {
                console.log(err);
              });
          })
          .catch(err => {
            console.log(err);
          })
      }
    },
    fetchUserStateR(data) {
      const r = confirm('정말 거절하시겠습니까 ?');
      const userData = {
        token: this.$store.state.token,
        appcode: data.appcode,
        chgcode: 'R',
      }

      if (r) {
        petchUserState(userData)
          .then(response => {
            alert(response.data.resultmsg);

            getUser(userData)
              .then(response => {
                this.$store.state.user = response.data;
              })
              .catch(err => {
                console.log(err);
              });
          })
          .catch(err => {
            console.log(err);
          })
      }
    },
    fetchUserStateC(data) {
      const c = confirm('정말 중단하시겠습니까 ?');
      const userData = {
        token: this.$store.state.token,
        appcode: data.appcode,
        chgcode: 'C',
      }

      if (c) {
        petchUserState(userData)
          .then(response => {
            alert(response.data.resultmsg);

            getUser(userData)
              .then(response => {
                this.$store.state.user = response.data;
              })
              .catch(err => {
                console.log(err);
              });
          })
          .catch(err => {
            console.log(err);
          })
      }
    },
    serachOptBtn() {
      this.page = 1;

      const userData = {
        token: this.$store.state.token,
        searchkey: this.serachkey,
        pagesize: this.pagesize,
        page: this.page,

      };

      getUser(userData)
        .then(response => {
          this.$store.state.user = response.data;

          this.pageLength();
        })
        .catch(err => {
          console.log(err);
        });
    },
    serachBtn() {
      if (this.serachkey && this.page > 1) {
        this.serachkey = '';
      } else if (this.serachkey) {
        this.page = 1;
      }

      const userData = {
        token: this.$store.state.token,
        searchkey: this.serachkey,
        pagesize: this.pagesize,
        page: this.page,

      };

      getUser(userData)
        .then(response => {
          this.$store.state.user = response.data;

          this.pageLength();
        })
        .catch(err => {
          console.log(err);
        });

      this.pageLength();
    }
  },
};
</script>

<style scoped>


.appstate_btn {
  width: 115px;
  display: inline-flex;
  justify-content: space-between;
  margin-left: 20px;
  position: absolute;
}
.appstate_btn button {
  padding: 0.1rem 1rem;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity .5s;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active below version 2.1.8 */
  {
  opacity: 0;
}
</style>
