<template>
    <div class="satisfaction_box">
        <div class="satisfaction_in_box">
            <i class="fas fa-times" @click="this.$emit('exit')"></i>
            <div class="satisfaction_tit">
                <h3>2022년 기업 채용 현장지원 사업 참여기업</h3>
                <h3>사업 만족도 설문조사</h3>
            </div>
            <ul>
                <li class="satisfaction_problem" :idx="1">
                    <h5>1. '현장지원'을 알게 된 경로는 무엇입니까?</h5>
                    <p value="1_/" v-if="satisfactionData.answer01 === '1_/'">① NCS 홈페이지</p>
                    <p value="2_/" v-else-if="satisfactionData.answer01 === '2_/'">② 지인 소개</p>
                    <p value="3_/" v-else-if="satisfactionData.answer01 === '3_/'">③ 운영기관의 안내(이메일, 전화 방문 등)</p>
                    <p value="4_/" v-else-if="satisfactionData.answer01 === '4_/'">④ 정부기관이나 기타 관련단체의 안내</p>
                    <p value="5_/" v-else-if="satisfactionData.answer01 === '5_/'">⑤ 광고 등 홍보물(배너, SNS, 유튜브 등)</p>
                    <p value="6_/" v-else-if="satisfactionData.answer01 === '6_/'" style="display: inline-block;">⑥ 기타</p>
                    <p class="etc_inp" v-if="satisfactionData.answer01 === '6_/'">{{ satisfactionData.etc01 }}</p>
                </li>
                <li class="satisfaction_problem" :idx="2">
                    <h5>2. '현장지원' 에 대한 만족도는 어떠합니까?</h5>
                    <p value="1_/" v-if="satisfactionData.answer02 === '1_/'">① 매우 높다</p>
                    <p value="2_/" v-else-if="satisfactionData.answer02 === '2_/'">② 높다</p>
                    <p value="3_/" v-else-if="satisfactionData.answer02 === '3_/'">③ 보통이다</p>
                    <p value="4_/" v-else-if="satisfactionData.answer02 === '4_/'">④ 낮다</p>
                    <p value="5_/" v-else-if="satisfactionData.answer02 === '5_/'">⑤ 매우 낮다</p>
                </li>
                <li class="satisfaction_problem" :idx="3">
                    <h5>
                        3. '현장지원' 에 참여하시기 전, 아래 중 해당하는 사항에 대해 모두 응답해주십시오.<br>
                        (복수응답 가능)
                    </h5>
                    <p value="1_/" v-if="satisfactionData.answer03 === '1_/'">① NCS 기반 능력중심 채용의 개념에 대해서 알고 있었음</p>
                    <p value="2_/" v-else-if="satisfactionData.answer03 === '2_/'">② 공정채용(블라인드 채용)의 개념에 대해서 알고 있었음</p>
                    <p value="3_/" v-else-if="satisfactionData.answer03 === '3_/'">③ 상기 채용형태에 대해서 들어봤지만 구체적인 개념은 몰랐음</p>
                    <p value="4_/" v-else-if="satisfactionData.answer03 === '4_/'">④ 들어본 적 없음</p>
                    <p value="5_/" v-else-if="satisfactionData.answer03 === '5_/'" style="display: inline-block;">⑤ 기타</p>
                    <p class="etc_inp" v-if="satisfactionData.answer01 === '5_/'">{{ satisfactionData.etc03 }}</p>
                </li>
                <li class="satisfaction_problem" :idx="4">
                    <h5>4. '현장지원' 이 NCS 기반 능력중심 채용·공정채용에 대한 이해를 높이는 데에 도움이 되었습니까?</h5>
                    <p value="1_/" v-if="satisfactionData.answer04 === '1_/'">① 매우 그렇다</p>
                    <p value="2_/" v-else-if="satisfactionData.answer04 === '2_/'">② 그렇다</p>
                    <p value="3_/" v-else-if="satisfactionData.answer04 === '3_/'">③ 보통이다</p>
                    <p value="4_/" v-else-if="satisfactionData.answer04 === '4_/'">④ 그렇지 않다</p>
                    <p value="5_/" v-else-if="satisfactionData.answer04 === '5_/'">⑤ 전혀 그렇지 않다</p>
                </li>
                <li class="satisfaction_problem" :idx="5">
                    <h5>5. '현장지원' 이 채용업무 부담 및 채용비용 절감에 도움이 되었습니까?</h5>
                    <p value="1_/" v-if="satisfactionData.answer05 === '1_/'">① 매우 그렇다</p>
                    <p value="2_/" v-else-if="satisfactionData.answer05 === '2_/'">② 그렇다</p>
                    <p value="3_/" v-else-if="satisfactionData.answer05 === '3_/'">③ 보통이다</p>
                    <p value="4_/" v-else-if="satisfactionData.answer05 === '4_/'">④ 그렇지 않다</p>
                    <p value="5_/" v-else-if="satisfactionData.answer05 === '5_/'">⑤ 전혀 그렇지 않다</p>
                </li>
                <li class="satisfaction_problem" :idx="6">
                    <h5>6. '현장지원' 이 기존의 채용절차를 개선하는 데에 도움이 되었습니까?</h5>
                    <p value="1_/" v-if="satisfactionData.answer06 === '1_/'">① 매우 그렇다</p>
                    <p value="2_/" v-if="satisfactionData.answer06 === '2_/'">② 그렇다</p>
                    <p value="3_/" v-if="satisfactionData.answer06 === '3_/'">③ 보통이다</p>
                    <p value="4_/" v-if="satisfactionData.answer06 === '4_/'">④ 그렇지 않다</p>
                    <p value="5_/" v-if="satisfactionData.answer06 === '5_/'">⑤ 전혀 그렇지 않다</p>
                </li>
                <li class="satisfaction_problem" :idx="7">
                    <h5>7. (5의 ① ~ ③에 답한 경우) 사업 과정 중, 어떤 부분이 채용절차를 개선하는 데에 가장 도움이 되었습니까?</h5>
                    <p value="1_/" v-if="satisfactionData.answer07 === '1_/'">① 직무능력중심 채용계획 수립</p>
                    <p value="2_/" v-if="satisfactionData.answer07 === '2_/'">② 전형별 평가도구 개발 및 활용</p>
                    <p value="3_/" v-if="satisfactionData.answer07 === '3_/'">③ 채용 프로세스 전반 체계화</p>
                    <p value="4_/" v-if="satisfactionData.answer07 === '4_/'">④ 채용업무 부담 및 비용 절감</p>
                    <p value="5_/" v-if="satisfactionData.answer07 === '5_/'" style="display: inline-block;">⑤ 기타</p>
                    <p class="etc_inp" v-if="satisfactionData.answer07 === '5_/'">{{ satisfactionData.etc07 }}</p>
                </li>
                <li class="satisfaction_problem" :idx="8">
                    <h5>8. (5의 ④ ~ ⑤에 답한 경우) 귀사의 채용절차를 개선하는 데에 본 사업이 도움이 되기 위해서, 사업 과정 중 개선이 필요한 부분이 무엇이라고 생각합니까?</h5>
                    <p value="1_/" v-if="satisfactionData.answer08 === '1_/'">① 지원 범위 확대</p>
                    <p value="2_/" v-if="satisfactionData.answer08 === '2_/'">② 평가도구의 현장 활용도 제고</p>
                    <p value="3_/" v-if="satisfactionData.answer08 === '3_/'">③ 평가도구 활용방법 관련 교육</p>
                    <p value="4_/" v-if="satisfactionData.answer08 === '4_/'">④ 평가도구의 다양화</p>
                    <p value="5_/" v-if="satisfactionData.answer08 === '5_/'" style="display: inline-block;">⑤ 기타</p>
                    <p class="etc_inp" v-if="satisfactionData.answer08 === '5_/'">{{ satisfactionData.etc08 }}</p>
                </li>
                <li class="satisfaction_problem" :idx="9">
                    <h5>9. '현장지원' 에 참여하기 전, 기존에 활용하셨던 채용도구를 모두 표기해주시기바랍니다. (복수응답 가능)</h5>
                    <p>
                        <span class="answernineEl" v-for="(answer, index) in answer09" :key="index">{{ answer }}</span>
                    </p>
                    <p v-if="satisfactionData.etc09">
                        <span class="answernineEl">⑨ 기타 </span>
                        <span class="etc_inp">{{ satisfactionData.etc09 }}</span>
                    </p>
                </li>
                <li class="satisfaction_problem" :idx="10">
                    <h5>10. 아래 표의 왼쪽(1, 2열)에 지원 대상 직무 개수 및 채용(예정) 인원을 기입해 주시고,<br>
                        지원받는 사항에 대한 만족도(직무능력과 관련성이 높은 사항을 검증하는데 도움이 된 정도)<br>
                        를 "1~5점" 까지 표기해 주십시오.</h5>
                    <p><strong>* 만족도 점수: 5(매우 만족), 4(만족), 3(보통), 2(불만족), 1(매우 불만족), 해당없음</strong></p>
                    <table class="table">
                        <thead>
                            <th>직무 개수</th>
                            <th>채용(예정)인원</th>
                            <th>채용계획검토</th>
                            <th>평가도구검토</th>
                            <th>직무분석</th>
                            <th>서류전형 평가도구</th>
                            <th>면접전형 평가도구</th>
                            <th>채용접수 페이지</th>
                        </thead>
                        <tbody class="table-group-divider">
                            <tr>
                                <td>{{ answer10[0] }}</td>
                                <td>{{ answer10[1] }}</td>
                                <td>{{ answer10[2] }}</td>
                                <td>{{ answer10[3] }}</td>
                                <td>{{ answer10[4] }}</td>
                                <td>{{ answer10[5] }}</td>
                                <td>{{ answer10[6] }}</td>
                                <td>{{ answer10[7] }}</td>
                            </tr>
                        </tbody>
                    </table>
                </li>
                <li class="satisfaction_problem" :idx="11">
                    <h5>11. 본 사업과 관련하여 개선되었으면 하는 사항 등 기타 의견이 있으시다면 자유롭게 기재해주십시오.</h5>
                    <p class="answer11-text">{{ satisfactionData.answer11 }}</p>
                </li>
                <p>설문조사 완료일: {{ createdDate }}</p>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        satisfactionData: {
            type: Object,
        },
    },
    data() {
        return {
            answer09: [],
            answer10: []
        }
    },
    computed: {
        createdDate() {
            const date = new Date(this.satisfactionData.createdt);
            const year = date.getFullYear();
            const mouth = date.getMonth();
            const days = date.getDate();
            const hours = date.getHours();
            const minutes = date.getMinutes();
            const seconds = date.getSeconds();

            return `${year}년 ${mouth + 1}월 ${days}일 ${hours}시 ${minutes}분 ${seconds}초`;
        }
    },
    mounted () {
        this.answer09ReNamed();
        this.answer10ReNamed();
    },
    methods: {
        answer09ReNamed() {
            this.answer09 = this.satisfactionData.answer09.split('_/');
            // split를 했을때 마지막 공백 제거
            this.answer09.pop();

            const changeAnswer09 = this.answer09.map(v => {
                if (v === '1') return v = '① 직무기술서';
                else if (v === '2') return v = '② 채용 공고문';
                else if (v === '3') return v = '③ 입사지원서';
                else if (v === '4') return v = '④ 자기소개서';
                else if (v === '5') return v = '⑤ 경험·경력기술서';
                else if (v === '6') return v = '⑥ 필기전형(필기문항)';
                else if (v === '7') return v = '⑦ 면접전형(면접문항)';
                else if (v === '8') return v = '⑧ 온라인 채용접수페이지';
            });

            this.answer09 = changeAnswer09;
        },
        answer10ReNamed() {
            this.answer10 = this.satisfactionData.answer10.split('_/');
            this.answer10.pop();
        }
    },
}
</script>

<style scoped>
.bold_text {
    font-weight: bold;
}

.fa-times {
    color: black;
    font-size: 40px;
    position: absolute;
    right: 2%;
    top: 3%;
    cursor: pointer;
}

ul,
li {
    list-style: none;
}

.satisfaction_box {
    width: 100%;
    height: 100vh;
    border: 1px solid gray;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10000;
}

.satisfaction_box::before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.5;
    background-color: black;
    z-index: -1;
}

.satisfaction_in_box {
    width: 95%;
    height: 90vh;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: calc(-90vh / 2);
    margin-left: calc(-95% / 2);
    text-align: center;
    padding: 30px;
    border-radius: 25px;
    overflow-y: scroll;
    background-color: white;
}

.satisfaction_tit {
    margin-bottom: 100px;
}

.satisfaction_sup,
.satisfaction_problem {
    text-align: left;
}

.etc_inp {
    width: 800px;
    margin-left: 20px;
}

.satisfaction_sup {
    margin-bottom: 50px;
}

.satisfaction_problem {
    margin-bottom: 50px;
}

.satisfaction_problem h5 {
    margin-bottom: 30px;
}

.satisfaction_problem p {
    line-height: 15px;
    color: black;
}

.satisfaction_problem p span {
    margin: 0 15px;
}

.satisfaction_problem p span:first-child {
    margin-left: 0;

}

.table {
    border: 1px solid #e9ecef;
    font-size: 12px;
    text-align: center;
}

.table thead th {
    border: 1px solid #e9ecef;
}

.table tbody tr td {
    border: 1px solid #e9ecef !important;
    vertical-align: middle;
}

.table tbody tr td input[type="text"],
.table tbody tr td input[type="number"] {
    width: 100%;
    border: 0;
    text-align: center;
}

.answer11-text {
    width: 100%;
    height: 200px;
    padding: 15px;
    border: 1px solid black;
    resize: none;
}
</style>