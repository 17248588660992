<template>
    <div class="py-4 container-fluid">
        <div class="row">
            <div class="col-12">
                <projects-table />
            </div>
        </div>
    </div>
</template>

<script>
import ProjectsTable from "./components/ProjectsTable";
import {getUser, getProfile} from '../api/index.js';

export default {
    name: "projects",
    components: {
        ProjectsTable,
    },
    created() {
        const userData = {
            "token": this.$store.state.token,
        };

        getUser(userData)
            .then(response => {
                this.$store.state.user = response.data;
            })
            .catch(err => {
                console.log(err);
            });

        const profileData = {
            "email": this.$store.state.userEmail,
            "token": this.$store.state.token,
        }

        getProfile(profileData)
            .then(response => {
                this.$store.state.myprofile = response.data;
            })
            .catch(err => {
                console.log(err);
            });
    },
};
</script>
