<template>
  <div class="container top-0 position-sticky z-index-sticky">
    <div class="row">
      <div class="col-12">
      </div>
    </div>
  </div>
  <main class="mt-0 main-content main-content-bg">
    <section>
      <div class="page-header min-vh-75">
        <div class="container">
          <div class="row">
            <div class="mx-auto col-xl-4 col-lg-5 col-md-6 d-flex flex-column">
              <div class="mt-8 card card-plain">
                <div class="pb-0 card-header text-start">
                  <h3 class="font-weight-bolder text-success text-gradient">
                    기업채용 현장지원 관리자
                  </h3>
                  <p class="mb-0">이메일과 비밀번호를 입력하고 로그인하세요.</p>
                </div>
                <div class="card-body">
                  <label>이메일</label>
                  <vsud-input id="email" type="email" placeholder="이메일" name="email" @input="typing"
                    @keyup.enter="userLoginBtn" />
                  <label>비밀번호</label>
                  <vsud-input id="password" type="password" placeholder="비밀번호" name="password" @input="typing"
                    @keyup.enter="userLoginBtn" />
                  <!-- <vsud-switch id="rememberMe" name="rememberMe" checked>
                    이메일 저장
                  </vsud-switch> -->
                  <div class="text-center">
                    <vsud-button class="my-4 mb-2" variant="gradient" color="success" full-width @click="userLoginBtn">
                      로그인
                    </vsud-button>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="top-0 oblique position-absolute h-100 d-md-block d-none me-n8">
                <div class="bg-cover oblique-image position-absolute fixed-top ms-auto h-100 z-index-0 ms-n6" :style="{
                    backgroundImage:
                      'url(' +
                      require('@/assets/img/curved-images/curved9.jpg') +
                      ')',
                  }"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import VsudInput from "@/components/VsudInput.vue";
// import VsudSwitch from "@/components/VsudSwitch.vue";
import VsudButton from "@/components/VsudButton.vue";
import { userLogin } from '@/api/index';
const body = document.getElementsByTagName("body")[0];
import { mapMutations } from "vuex";

export default {
  name: "SignIn",
  components: {
    VsudInput,
    // VsudSwitch,
    VsudButton,
  },
  data() {
    return {
      useremail: '',
      userpwd: ''
    }
  },
  created() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    body.classList.remove("bg-gray-100");
  },
  beforeUnmount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    body.classList.add("bg-gray-100");
  },
  methods: {
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),

    // 아이디, 비밀번호 저장하는 이벤트
    typing(e) {
      const email = document.querySelector('#email');
      const pwd = document.querySelector('#password');

      if (e.target === email) {
        this.useremail = e.target.value;
      } else if(e.target === pwd) {
        this.userpwd = e.target.value;
      }
    },

    // 유저 로그인 이벤트
    userLoginBtn() {
      if (this.useremail && this.userpwd) {
        const data = {
          useremail: this.useremail,
          userpwd: this.userpwd,
        };

        userLogin(data)
          .then((response) => {
            if (response.data.resultcode === 1) { // 로그인 성공
              alert(response.data.resultmsg);
              this.$store.state.loginUser = response.data;
              this.$store.state.userEmail = this.useremail;
              document.cookie = `userLogin=${response.data.token}`;

              localStorage.setItem('loginEmail', this.useremail);

              if (response.data.authority === 9) {
                this.$router.push('/dashboard');
              } else {
                this.$router.push('/tables');
              }
            } else { // 로그인 실패
              alert(response.data.resultmsg);
            }
          })
          .catch((err) => {
            console.log(err);

          })
      } else {
        alert('계정 정보를 입력해주시길 바랍니다.');
      }
    },
  },
};
</script>
