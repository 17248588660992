<template>
  <div class="card h-100">
    <div class=" p-3 pb-0 card-header">
      <div class=" row">
        <div class="col-md-8 d-flex align-items-center">
          <h4 class="mb-0">프로필 정보</h4>
        </div>
        <div class="col-md-4 text-end">
          <a :href="action.route">
            <i class="text-sm fas fa-user-edit text-secondary" data-bs-toggle="tooltip" data-bs-placement="top"
              :title="action.tooltip" @click="fetchUserClick"></i>
          </a>
        </div>
      </div>
    </div>
    <div class="p-3 card-body" v-if="!fetchUser">
      <hr class="my-1 horizontal gray-light" />
      <ul class="list-group">
        <li class="pt-0 text-sm border-0 list-group-item ps-0">
          <strong class="text-dark">업체명:</strong> &nbsp;
          {{ this.$store.state.myprofile.companyname }}
        </li>
        <li class="text-sm border-0 list-group-item ps-0">
          <strong class="text-dark">담당자:</strong> &nbsp;
          {{ this.$store.state.myprofile.workername }}
        </li>
        <li class="text-sm border-0 list-group-item ps-0">
          <strong class="text-dark">직급:</strong> &nbsp;
          {{ this.$store.state.myprofile.position }}
        </li>
        <li class="text-sm border-0 list-group-item ps-0">
          <strong class="text-dark">소속:</strong> &nbsp;
          {{ this.$store.state.myprofile.deptname }}
        </li>
        <li class="text-sm border-0 list-group-item ps-0">
          <strong class="text-dark">연락처:</strong> &nbsp;
          {{ this.$store.state.myprofile.contactno }}
        </li>
        <li class="text-sm border-0 list-group-item ps-0">
          <strong class="text-dark">이메일:</strong> &nbsp;
          {{ this.$store.state.myprofile.email }}
        </li>
      </ul>
    </div>
    <div class="p-3 card-body" v-else>
      <hr class="my-1 horizontal gray-light" />
      <ul class="list-group">
        <li class="pt-0 text-sm border-0 list-group-item ps-0">
          <strong class="text-dark">업체명:</strong> &nbsp;
          {{ this.$store.state.myprofile.companyname }}
        </li>
        <li class="text-sm border-0 list-group-item ps-0">
          <strong class="text-dark">담당자:</strong> &nbsp;
          <input type="text" v-model="this.$store.state.myprofile.workername">
        </li>
        <li class="text-sm border-0 list-group-item ps-0">
          <strong class="text-dark">직급:</strong> &nbsp;
          <input type="text" v-model="this.$store.state.myprofile.position">
        </li>
        <li class="text-sm border-0 list-group-item ps-0">
          <strong class="text-dark">소속:</strong> &nbsp;
          <input type="text" v-model="this.$store.state.myprofile.deptname">
        </li>
        <li class="text-sm border-0 list-group-item ps-0">
          <strong class="text-dark">연락처:</strong> &nbsp;
          <input type="tel" v-model="this.$store.state.myprofile.contactno">
        </li>
        <li class="text-sm border-0 list-group-item ps-0">
          <strong class="text-dark">이메일:</strong> &nbsp;
          <input type="email" v-model="this.$store.state.myprofile.email">
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { putProfile, getProfile } from '../../api/index';

export default {
  name: "ProfileInfoCard",
  props: {
    title: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
    info: {
      type: Object,
      fullName: String,
      mobile: String,
      email: String,
      location: String,
      default: () => {},
    },
    social: {
      type: Array,
      link: String,
      icon: String,
      default: () => [],
    },
    action: {
      type: Object,
      route: String,
      tooltip: String,
      default: () => ({
        route: "javascript:;",
      }),
    },
  },
  data() {
    return {
      fetchUser: false,
    }
  },
  methods: {
    fetchUserClick() {
      if (this.fetchUser) {
        const result = confirm("프로필을 수정하시겠습니까?");
        if (result) {
          const userData = {
            token: this.$store.state.token,
            email: this.$store.state.myprofile.email,
            deptname: this.$store.state.myprofile.deptname,
            workname: this.$store.state.myprofile.workername,
            position: this.$store.state.myprofile.position,
            contactno: this.$store.state.myprofile.contactno,
          }

          const profileData = {
            email: this.$store.state.userEmail,
            token: this.$store.state.token,
          }

          putProfile(userData)
            .then(response => {
              console.log(response.data);

              getProfile(profileData)
                .then(response => {
                  alert(response.data.resultmsg)
                  this.$store.state.myprofile = response.data;
                })
                .catch(err => {
                  console.log(err);
                })
            })
            .catch(err => {
              console.log(err);
            })
        }

        this.fetchUser = false;
      } else {
        this.fetchUser = true;
      }
    }
  },
};
</script>
