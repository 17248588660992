<template>
    <div class="password_box">
        <h3>변경하실 비밀번호를 입력해 주시기 바랍니다.</h3>
        <div class="password_in_box">
            <small>현재 비밀번호</small>
            <input type="password" v-model="userPassword" @keyup.enter="passwordChange">
        </div>
        <div class="password_in_box">
            <small>새 비밀번호</small>
            <input type="password" v-model="newPassword" @input="inputPasswordCheck" @keyup.enter="passwordChange">
        </div>
        <div class="password_in_box">
            <small>새 비밀번호 확인</small>
            <input type="password" v-model="newPasswordCheck" @input="inputPasswordCheck" @keyup.enter="passwordChange">
            <small v-if="newPasswordChecking" style="width: 30%; color: red; font-weight: bold; margin-left: 25px;">서로
                비밀번호가 맞지 않습니다.</small>
        </div>
        <div class="password_in_box">
            <button class="btn btn-info btn-sm" @click="passwordChange">확인</button>
            <button class="btn btn-info btn-sm" @click="passwordChangeBtn">취소</button>
        </div>
    </div>
</template>

<script>
import { userPasswordChange } from '../../api/index';

export default {
    data() {
        return {
            userPassword: '',
            newPassword: '',
            newPasswordCheck: '',
            newPasswordChecking: false,
        }
    },
    methods: {
        passwordChangeBtn() {
            this.$emit('exit');
        },
        passwordChange() {
            const userData = {
                token: this.$store.state.token,
                useremail: this.$store.state.userEmail,
                userpwd: this.userPassword,
                newpwd: this.newPassword,
            }

            if (!this.userPassword || !this.newPassword || !this.newPasswordCheck) {
                return alert('모두 입력 해 주시길 바랍니다.');
            }

            if (!this.newPasswordChecking) {
                userPasswordChange(userData)
                    .then(response => {
                        if (response.data.resultcode === 1) {
                            alert(response.data.resultmsg);

                            this.userPassword = '';
                            this.newPassword = '';
                            this.newPasswordCheck = '';

                            this.$emit('exit');
                        } else {
                            alert(response.data.resultmsg);
                        }
                    })
                    .catch(err => {
                        console.log(err);
                    })
            } else {
                alert('비밀번호가 맞지 않습니다.\n다시 입력해주시길 바랍니다.');
            }
        },
        inputPasswordCheck() {
            if (this.newPassword === this.newPasswordCheck) {
                this.newPasswordChecking = false;
            } else {
                this.newPasswordChecking = true;
            }
        }
    },
}
</script>

<style scoped>
.password_box {
    width: 50%;
    height: 350px;
    padding: 30px;
    position: fixed;
    top: 50%;
    left: 50%;
    margin-top: calc(-350px / 2);
    margin-left: calc(-50%/ 2);
    border: 1px solid lightgray;
    box-shadow: 0 0 20px 0px grey;
    background-color: white;
    z-index: 10000;
}

.password_in_box {
    margin-top: 25px;
}

.password_in_box small {
    width: 18%;
    display: inline-block;
}

.password_in_box input {
    padding: 0 20px;
}

.password_in_box:last-child {
    width: 200px;
    justify-content: space-between;
    display: flex;
    position: absolute;
    bottom: 0;
}

.password_in_box button {
    margin-right: 5%;
}
</style>