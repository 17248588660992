<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <authors-table />
      </div>
    </div>
  </div>
</template>

<script>
import { getUser, getProfile } from '../api/index';
import AuthorsTable from "./components/AuthorsTable";

export default {
  name: "tables",
  components: {
    AuthorsTable,
  },
  created () {
    const userData = {
      "token": this.$store.state.token,
    };

    getUser(userData)
      .then(response => {
        this.$store.state.user = response.data;
      })
      .catch(err => {
        console.log(err);
      });

    const profileData = {
      "email": this.$store.state.userEmail,
      "token": this.$store.state.token,
    }

    getProfile(profileData)
      .then(response => {
        this.$store.state.myprofile = response.data;
      })
      .catch(err => {
        console.log(err);
      });
  },
};
</script>
