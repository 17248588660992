import axios from 'axios';

const config = {
    baseUrl: 'https://admin.recruitsupport.kr/api/'
    // baseUrl: 'https://admin.exchrm.ml/api/',
    // baseUrl: 'http://127.0.0.1:8000/api/',
}

// 로그인 API / SignIn.vue
function userLogin(userData) {
    return axios.post(`${config.baseUrl}login/`, userData);
}

// 쿠키 있을 시 로그인 API
function userReLogin(cookie) {
    return axios.get(`${config.baseUrl}relogin/`, { headers: { 'Authorization': `Token ${cookie}` } });
}

// 기관 정보 가져오기 API / Dashboard.vue
function getUser(userData) {
    return axios.post(`${config.baseUrl}company/`, userData, { headers: {'Authorization': `Token ${userData.token}`} });
}

// 기관 상세 페이지 API / Details.vue
function getUserDetail(userData) {
    return axios.post(`${config.baseUrl}company/${userData.appcode}/`, userData, { headers: {'Authorization': `Token ${userData.token}`} });
}

// 기관 상세 페이지 수정 API / Details.vue
function putUserDetail(userData) {
    return axios.put(`${config.baseUrl}company/${userData.appcode}/`, userData, { headers: { 'Authorization': `Token ${userData.token}` } });
}

// 기관 상태 변경 API / Details.vue, AuthorsTable.vue
function petchUserState(userData) {
    return axios.put(`${config.baseUrl}company/${userData.appcode}/chgstatus/`, userData, { headers: { 'Authorization': `Token ${userData.token}` } });
}

// 통계 API / ProjectsTable.vue
function getProjects(userData) {
    return axios.post(`${config.baseUrl}project/`, userData, { headers: { 'Authorization': `Token ${userData.token}` } });
}

// QnA API / QuestionsTable.vue
function getQnA(userData) {
    return axios.post(`${config.baseUrl}qna/`, userData, { headers: {'Authorization': `Token ${userData.token}`} });
}

// QnA 상세 페이지 API / QuestionDetail.vue
function getQnADetail(userData) {
    return axios.post(`${config.baseUrl}qna/${userData.seq}/`, userData, { headers: {'Authorization': `Token ${userData.token}`} });
}

// 업로드 파일 불러오기 API / Details.vue
function getFiles(userData) {
    return axios.post(`${config.baseUrl}company/${userData.appcode}/addfilelist/`, userData, { headers: { 'Authorization': `Token ${userData.token}` } })
}

// 파일 업로드 API / Details.vue
function userFileUploads(formData, userData) {
    return axios.post(`${config.baseUrl}company/${userData.appcode}/addfile/`, formData, { headers: { 'Authorization': `Token ${userData.token}` } });
}

// 파일 삭제 API / Details.vue
function deleteFile(userData, token) {
    return axios.delete(`${config.baseUrl}company/${userData.appcode}/delfile/`, { data: userData, headers: { 'Authorization': `Token ${token}` } })
}

// 만족도 조사 제출 API / Satisfaction.vue
function postSatisfaction(userData) {
    return axios.post(`${config.baseUrl}survey/`, userData, { headers: { 'Authorization': `Token ${userData.token}` } });
}

// 만족도 조사 열람 API
function getSatisfaction(userData) {
    return axios.get(`${config.baseUrl}survey/${userData.code}/`, { headers: { 'Authorization': `Token ${userData.token}` } })
}

// QnA 답변하기 API / QuestionDetail.vue
function putQnA(userData) { 
    return axios.put(`${config.baseUrl}qna/${userData.seq}/`, userData, { headers: {'Authorization': `Token ${userData.token}`} });
}

// QnA 답변 수정하기 API / QuestionDetail.vue
function fetchQnA(userData) {
    return axios.put(`${config.baseUrl}qna/${userData.seq}/`, userData, { headers: { 'Authorization': `Token ${userData.token}` } });
}

// 내 프로필 API / Dashboard.vue
function getProfile(userData) {
    return axios.post(`${config.baseUrl}profile/${userData.email}/`, userData, { headers: {'Authorization': `Token ${userData.token}`} });
}

// 내 프로필 수정 API / ProfileInfoCard.vue
function putProfile(userData) {
    return axios.put(`${config.baseUrl}profile/${userData.email}/`, userData, { headers: {'Authorization': `Token ${userData.token}`} });
}

// 비밀번호 변경 API
function userPasswordChange(userData) {
    return axios.post(`${config.baseUrl}changepwd/`, userData, { headers: { 'Authorization': `Token ${userData.token}` } })
}

export {
    userLogin,
    userReLogin,
    getUser,
    getUserDetail,
    putUserDetail,
    getFiles,
    userFileUploads,
    deleteFile,
    petchUserState,
    postSatisfaction,
    getSatisfaction,
    getProjects,
    getQnA,
    getQnADetail,
    putQnA,
    fetchQnA,
    getProfile,
    putProfile,
    userPasswordChange,
};