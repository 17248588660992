<template>
  <!-- <div @click="clickGetTest">테스트</div> -->
  <sidenav :custom_class="this.$store.state.mcolor" :class="[
    this.$store.state.isTransparent,
    this.$store.state.isRTL ? 'fixed-end' : 'fixed-start',
  ]" v-if="this.$store.state.showSidenav" />
  <main class="main-content position-relative max-height-vh-100 h-100 border-radius-lg"
    :style="this.$store.state.isRTL ? 'overflow-x: hidden' : ''">
    <!-- nav -->
    <navbar :class="[navClasses]" :textWhite="this.$store.state.isAbsolute ? 'text-white opacity-8' : ''"
      :minNav="navbarMinimize" v-if="this.$store.state.showNavbar" />
    <router-view />
  </main>
</template>
<script>
import Sidenav from "./examples/Sidenav";
import Navbar from "@/examples/Navbars/Navbar.vue";
import { mapMutations } from "vuex";
// import axios from 'axios';

export default {
  name: "App",
  components: {
    Sidenav,
    Navbar,
  },
  methods: {
    ...mapMutations(["navbarMinimize"]),
    // clickGetTest() {
    //   axios.get(`https://admin.exchrm.ml/download/?path=2022/07/18/1040695dd4ab4157a9ed0dd0b398040f_20220718145413.pptx&fname=%EC%96%B4%ED%95%99%EC%84%B1%EC%A0%81%20%EC%83%81%EC%8B%9C%20%EC%A1%B0%ED%9A%8C%20%EC%84%9C%EB%B9%84%EC%8A%A4.pptx`, { headers: { 'Authorization': `Token ${this.$store.state.token}` } })
    //     .then(response => {
    //       console.log(response.data);
    //     })
    //     .catch(err => {
    //       console.log(err);
    //     })
    // }
  },
  computed: {
    navClasses() {
      return {
        "position-sticky blur shadow-blur mt-4 left-auto top-1 z-index-sticky": this
          .$store.state.isNavFixed,
        "position-absolute px-4 mx-0 w-100 z-index-2": this.$store.state
          .isAbsolute,
        "px-0 mx-4 mt-4": !this.$store.state.isAbsolute,
      };
    },
  },
  beforeMount() {
    this.$store.state.isTransparent = "bg-transparent";
  },
};
</script>
