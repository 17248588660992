<template>
  <div>
    <div class="card mb-4">
      <div class="card-header pb-0">
        <h6>통계</h6>
        <div class="card-top-menu">
          <div style="display: flex; justify-content: right; align-items: center;">
            <div class="date_box" style="display: flex; justify-content: right; align-items: center;">
              시작 기간: <input type="date" class="start_day" name="sday" required pattern="\d{4}-\d{2}-\d{2}"
                style="margin: 0 10px;" v-model="sDate">
              종료 기간: <input type="date" class="end_day" name="eday" required pattern="\d{4}-\d{2}-\d{2}"
                style="margin: 0 10px;" v-model="eDate">
            </div>
            <div style="margin-right: 10px;">
              <select style="height: 100%;" v-model="listNum" @change="listNumChangeData">
                <option value="10">10개보기</option>
                <option value="20" selected>20개보기</option>
                <option value="30">30개보기</option>
              </select>
            </div>
            <div class="input-group" style="width: 15%; float: right; display: flex;">
              <input type="text" class="form-control" placeholder="기관 검색" v-model="serachValue"
                @keyup.enter="searchData">
              <span class="input-group-text text-body">
                <i class="fas fa-search" aria-hidden="true" style="cursor: pointer;" @click="searchData"></i>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body px-0 pt-0 pb-2">
        <div class="table-responsive p-0">
          <table class="table align-items-center justify-content-center mb-0">
            <thead>
              <tr>
                <th class="text-uppercase text-secondary text-xxs font-weight-bolder text-center opacity-7">
                  No
                </th>
                <th class="text-uppercase text-secondary text-xxs font-weight-bolder text-center opacity-7 ps-2">
                  업체명
                </th>
                <th class="text-uppercase text-secondary text-xxs font-weight-bolder text-center opacity-7 ps-2 ">
                  KPI(공공)
                </th>
                <th class="text-uppercase text-secondary text-xxs font-weight-bolder text-center opacity-7 ps-2">
                  KPI(민간)
                </th>
                <th class="text-uppercase text-secondary text-xxs font-weight-bolder text-center opacity-7 ps-2">
                  개발직군
                </th>
                <th class="text-uppercase text-secondary text-xxs font-weight-bolder text-center opacity-7 ps-2">
                  채용계획검토
                </th>
                <th class="text-uppercase text-secondary text-xxs font-weight-bolder text-center opacity-7 ps-2">
                  직무분석
                </th>
                <th class="text-uppercase text-secondary text-xxs font-weight-bolder text-center opacity-7 ps-2">
                  서류전형
                </th>
                <th class="text-uppercase text-secondary text-xxs font-weight-bolder text-center opacity-7 ps-2">
                  면접전형
                </th>
                <th class="text-uppercase text-secondary text-xxs font-weight-bolder text-center opacity-7 ps-2">
                  평가 도구
                </th>
                <th class="text-uppercase text-secondary text-xxs font-weight-bolder text-center opacity-7 ps-2">
                  채용홈페이지 지원
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="project in projectData" :key="project" class="project">
                <td class="text-center">
                  <div class="px-2">
                    <div class="my-auto">
                      <h6 class="mb-0 text-sm">{{ project.rownum }}</h6>
                    </div>
                  </div>
                </td>
                <td>
                  <p class="text-sm font-weight-bold mb-0 text-center companyname">{{ project.companyname }}</p>
                </td>
                <td class="text-center">
                  <span v-if="project.companykind.title === 'G'">
                    {{ project.companykind.value }}
                  </span>
                </td>
                <td class="text-center">
                  <span v-if="project.companykind.title === 'P'">
                    {{ project.companykind.value }}
                  </span>
                </td>
                <td class="text-center">
                  <span>
                    {{ project.devjobcnt }}
                  </span>
                </td>
                <td class="text-center">
                  <span>
                    <i class="fa fa-solid fa-check" v-if="project.RP"></i>
                  </span>
                </td>
                <td class="text-center">
                  <span>
                    <i class="fa fa-solid fa-check" v-if="project.JA"></i>
                  </span>
                </td>
                <td class="text-center">
                  <span>
                    <i class="fa fa-solid fa-check" v-if="project.PT"></i>
                  </span>
                </td>
                <td class="text-center">
                  <span>
                    <i class="fa fa-solid fa-check" v-if="project.IT"></i>
                  </span>
                </td>
                <td class="text-center">
                  <span>
                    {{ project.ETcnt }}
                  </span>
                </td>
                <td class="text-center">
                  <span>
                    {{ project.AFcnt }}
                  </span>
                </td>
              </tr>
              <tr>
                <td colspan="11">
                  <br>
                  <br>
                  <br>
                </td>
              </tr>
              <tr>
                <td class="text-center">
                  <span>
                    Total
                  </span>
                </td>
                <td class="text-center">
                  <span>
                    {{ projectData.length }}
                  </span>
                </td>
                <td class="text-center">
                  <span>
                    {{ kpiLen }}
                  </span>
                </td>
                <td class="text-center">
                  <span>
                    {{ kpi2Len }}
                  </span>
                </td>
                <td class="text-center">
                  <span>
                    {{ devLen }}
                  </span>
                </td>
                <td class="text-center">
                  <span>
                    {{ rpLen }}
                  </span>
                </td>
                <td class="text-center">
                  <span>
                    {{ jaLen }}
                  </span>
                </td>
                <td class="text-center">
                  <span>
                    {{ ptLen }}
                  </span>
                </td>
                <td class="text-center">
                  <span>
                    {{ itLen }}
                  </span>
                </td>
                <td class="text-center">
                  <span>
                    {{ etCntLen }}
                  </span>
                </td>
                <td class="text-center">
                  <span>
                    {{ afCntLen }}
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="btn-group mr-2" role="group" aria-label="First group" style="float: right;" ref="userList"
      v-if="pageArr.length !== 1">
      <button type="button" class="btn btn-secondary" v-for="btn in pageArr" :key="btn" @click="changeList(btn)"
        :class="page === btn ? 'btn-info' : ''">{{ btn }}</button>
    </div>
  </div>
</template>

<script>
import { getProjects } from '../../api';

export default {
  data() {
    return {
      projectData: [],
      kpiLen: 0,
      kpi2Len: 0,
      devLen: 0,
      rpLen: 0,
      jaLen: 0,
      ptLen: 0,
      itLen: 0,
      afCntLen: 0,
      etCntLen: 0,
      sDate: '',
      eDate: '',
      sDateValue: '',
      eDateValue: '',
      listNum: 20,
      serachValue: '',
      page: 1,
      pageArr: [],
    }
  },
  created () {
    const userData = {
      token: this.$store.state.token,
    }

    getProjects(userData)
      .then(response => {
        this.projectData = response.data;
        let afCnt = 0;

        for(let i of response.data) {
          for (let data of this.projectData) {
            if (data.companykind === 'G') {
              data.companykind = {
                title: 'G',
                value: (data.devjobcnt * (data.RP + data.JA + data.PT + data.IT) + (data.ETcnt * 0.5))
              };
            }

            if (data.companykind === 'P') {
              data.companykind = {
                title: 'P',
                value: (data.devjobcnt * (data.RP + data.JA + data.PT + data.IT) + (data.ETcnt * 0.5))
              };
            }
          }

          this.devLen += i.devjobcnt;
          this.rpLen += i.RP;
          this.jaLen += i.JA;
          this.itLen += i.PT;
          this.itLen += i.IT;

          if (i.AFcnt) {
            this.afCntLen += i.AFcnt;
            afCnt = afCnt + 1;
          }

          this.etCntLen += i.ETcnt;

        } 
        let kpiLen = 0;
        let kpi2Len = 0;

        for (let data of this.projectData) {
          if (data.companykind.title === 'G') {
            this.kpiLen += data.companykind.value;
            kpiLen = kpiLen + 1;
          }

          if (data.companykind.title === 'P') {
            this.kpi2Len += data.companykind.value;
            kpi2Len = kpi2Len + 1;
          }
        }

        this.kpiLen = `${this.kpiLen}/80(${((this.kpiLen / 80) * 100).toFixed(1)}%)`
        this.kpi2Len = `${this.kpi2Len}/120(${((this.kpi2Len / 120) * 100).toFixed(1)}%)`
        this.afCntLen = `${this.afCntLen}/54(${((this.afCntLen / 54) * 100).toFixed(1)}%)`

        this.totalcnt();
      })
      .catch(err => {
        console.log(err);
      })
  },
  methods: {
    pageLength() {
      const maxUser = this.projectData[0] ? this.projectData[0].totalcnt : '1';
      const pageSize = Number(this.pagesize);

      const listVal = maxUser / pageSize;

      this.pageArr = [];
      for (let i = 1; i <= Math.ceil(listVal); i++) {
        this.pageArr.push(i);
      }
    },
    changeList(value) {
      this.page = value;

      this.serachBtn();
    },
    listNumChangeData() {
      this.sDateValue = '';
      this.eDateValue = '';
      this.devLen = 0;
      this.rpLen = 0;
      this.jaLen = 0;
      this.itLen = 0;
      this.itLen = 0;
      this.afCntLen = 0;
      this.kpiLen = 0;
      this.kpi2Len = 0;
      this.etCntLen = 0;

      let sDate, eDate = '';

      sDate = this.sDate.split('-');
      eDate = this.eDate.split('-');

      for (let s of sDate) {
        this.sDateValue += s;
      }

      for (let e of eDate) {
        this.eDateValue += e;
      }

      const userData = {
        token: this.$store.state.token,
        sdate: this.sDateValue,
        eDate: this.eDateValue,
        searchkey: this.serachValue,
        pagesize: this.listNum,
        page: 1,
      }

      getProjects(userData)
        .then(response => {
          this.projectData = response.data;
          let afCnt = 0;

          for (let i of response.data) {
            for (let data of this.projectData) {
              if (data.companykind === 'G') {
                data.companykind = {
                  title: 'G',
                  value: (data.devjobcnt * (data.RP + data.JA + data.PT + data.IT) + (data.ETcnt * 0.5))
                };
              }

              if (data.companykind === 'P') {
                data.companykind = {
                  title: 'P',
                  value: (data.devjobcnt * (data.RP + data.JA + data.PT + data.IT) + (data.ETcnt * 0.5))
                };
              }
            }

            this.devLen += i.devjobcnt;
            this.rpLen += i.RP;
            this.jaLen += i.JA;
            this.itLen += i.PT;
            this.itLen += i.IT;

            if (i.AFcnt) {
              this.afCntLen += i.AFcnt;
              afCnt = afCnt + 1;
            }

            this.etCntLen += i.ETcnt;

          }
          let kpiLen = 0;
          let kpi2Len = 0;

          for (let data of this.projectData) {
            if (data.companykind.title === 'G') {
              this.kpiLen += data.companykind.value;
              kpiLen = kpiLen + 1;
            }

            if (data.companykind.title === 'P') {
              this.kpi2Len += data.companykind.value;
              kpi2Len = kpi2Len + 1;
            }
          }

          this.kpiLen = `${this.kpiLen}/80(${((this.kpiLen / 80) * 100).toFixed(1)}%)`
          this.kpi2Len = `${this.kpi2Len}/120(${((this.kpi2Len / 120) * 100).toFixed(1)}%)`
          this.afCntLen = `${this.afCntLen}/54(${((this.afCntLen / 54) * 100).toFixed(1)}%)`

          this.totalcnt();
        })
        .catch(err => {
          console.log(err);
        })
    },
    searchData() {
      this.sDateValue = '';
      this.eDateValue = '';
      this.devLen = 0;
      this.rpLen = 0;
      this.jaLen = 0;
      this.itLen = 0;
      this.itLen = 0;
      this.afCntLen = 0;
      this.kpiLen = 0;
      this.kpi2Len = 0;
      this.etCntLen = 0;

      let sDate, eDate = '';

      sDate = this.sDate.split('-');
      eDate = this.eDate.split('-');

      for (let s of sDate) {
        this.sDateValue += s;
      }

      for (let e of eDate) {
        this.eDateValue += e;
      }
      
      const userData = {
        token: this.$store.state.token,
        sdate: this.sDateValue,
        eDate: this.eDateValue,
        searchkey: this.serachValue,
        pagesize: this.listNum,
        page: this.page,
      }

      getProjects(userData)
        .then(response => {
          this.projectData = response.data;
          let afCnt = 0;

          for (let i of response.data) {
            for (let data of this.projectData) {
              if (data.companykind === 'G') {
                data.companykind = {
                  title: 'G',
                  value: (data.devjobcnt * (data.RP + data.JA + data.PT + data.IT) + (data.ETcnt * 0.5))
                };
              }

              if (data.companykind === 'P') {
                data.companykind = {
                  title: 'P',
                  value: (data.devjobcnt * (data.RP + data.JA + data.PT + data.IT) + (data.ETcnt * 0.5))
                };
              }
            }

            this.devLen += i.devjobcnt;
            this.rpLen += i.RP;
            this.jaLen += i.JA;
            this.itLen += i.PT;
            this.itLen += i.IT;

            if (i.AFcnt) {
              this.afCntLen += i.AFcnt;
              afCnt = afCnt + 1;
            }

            this.etCntLen += i.ETcnt;

          }
          let kpiLen = 0;
          let kpi2Len = 0;

          for (let data of this.projectData) {
            if (data.companykind.title === 'G') {
              this.kpiLen += data.companykind.value;
              kpiLen = kpiLen + 1;
            }

            if (data.companykind.title === 'P') {
              this.kpi2Len += data.companykind.value;
              kpi2Len = kpi2Len + 1;
            }
          }

          this.kpiLen = `${this.kpiLen}/80(${((this.kpiLen / 80) * 100).toFixed(1)}%)`
          this.kpi2Len = `${this.kpi2Len}/120(${((this.kpi2Len / 120) * 100).toFixed(1)}%)`
          this.afCntLen = `${this.afCntLen}/54(${((this.afCntLen / 54) * 100).toFixed(1)}%)`

          this.totalcnt();
        })
        .catch(err => {
          console.log(err);
        })
    }
  },
};
</script>
