<template>
    <div class="details">
        <div class="details_box">
            <i class="fas fa-times" @click="detaileExit"></i>
            <div class="details_btn_box">
                <span style="margin-right: 15px;">현재 상태</span>
                <div style="display: inline-block;" v-if="detailsPut && this.$store.state.loginUser.authority === 9">
                    <select v-model="userData.appstate">
                        <option value="S">대기</option>
                        <option value="A">승인</option>
                        <option value="R">거부</option>
                        <option value="C">중단</option>
                    </select>
                </div>
                <div v-else style="display: inline-block;">
                    <span class="badge bg-warning" title="상태 변경" v-if="userData.appstate === 'S'">대기</span>
                    <span class="badge bg-success" title="상태 변경" v-else-if="userData.appstate === 'A'">승인</span>
                    <span class="badge bg-danger" title="상태 변경" v-else-if="userData.appstate === 'R'">거부</span>
                    <span class="badge bg-secondary" title="상태 변경" v-else>중단</span>
                </div>

                <button class="btn btn-info btn-sm" @click="detailsPutClick"
                    v-if="!detailsPut && (this.$store.state.loginUser.authority === 9 || this.$store.state.loginUser.authority === 1)">수정하기</button>
                <button class="btn btn-info btn-sm" @click="detailsPutClick"
                    v-else-if="detailsPut && (this.$store.state.loginUser.authority === 9 || this.$store.state.loginUser.authority === 1)">저장하기</button>
            </div>
            <div class="details_user">
                <table class="table text-center">
                    <thead>
                        <tr>
                            <th scope="col">업체명</th>
                            <th scope="col">담당자</th>
                            <th scope="col">소속</th>
                            <th scope="col">연락처</th>
                            <th scope="col">E-mail</th>
                            <th scope="col">컨설팅 이력</th>
                            <th scope="col">직무기술서 보유</th>
                            <th scope="col">평가지표 보유</th>
                        </tr>
                        <tr>
                            <td>
                                <p>{{ userData.companyname }}</p>
                            </td>
                            <td>
                                <p>{{ userData.workername }}</p>
                            </td>
                            <td>
                                <p>{{ userData.departname }} ({{ userData.position }})</p>
                            </td>
                            <td>
                                <p>{{ userData.contactno }}</p>
                            </td>
                            <td>
                                <p>{{ userData.email }}</p>
                            </td>
                            <td v-if="detailsPut && this.$store.state.loginUser.authority === 9">
                                <p><input type="checkbox" v-model="beforehandArr[0]"></p>
                            </td>
                            <td v-else>
                                <p v-if="beforehandArr[0] === true">Y</p>
                                <p v-else>N</p>
                            </td>
                            <td v-if="detailsPut && this.$store.state.loginUser.authority === 9">
                                <p><input type="checkbox" v-model="beforehandArr[1]"></p>
                            </td>
                            <td v-else>
                                <p v-if="beforehandArr[1] === true">Y</p>
                                <p v-else>N</p>
                            </td>
                            <td v-if="detailsPut && this.$store.state.loginUser.authority === 9">
                                <p><input type="checkbox" v-model="beforehandArr[2]"></p>
                            </td>
                            <td v-else>
                                <p v-if="beforehandArr[2] === true">Y</p>
                                <p v-else>N</p>
                            </td>
                        </tr>
                    </thead>
                </table>

                <table class="table text-center" v-if="!detailsPut">
                    <thead>
                        <tr>
                            <th scope="col" style="text-align: left;">비고</th>
                        </tr>
                        <tr>
                            <td style="text-align: left; padding: 10px 20px;">{{ userData.bigo || '없음' }}</td>
                        </tr>
                    </thead>
                </table>
                <table class="table text-center" v-else>
                    <thead>
                        <tr>
                            <th scope="col" style="text-align: left;">비고</th>
                        </tr>
                        <tr>
                            <td>
                                <textarea class="detail_bigo_textarea" v-model="userData.bigo"></textarea>
                            </td>
                        </tr>
                    </thead>
                </table>

                <table class="table text-center">
                    <thead>
                        <tr>
                            <th scope="col">
                                <span>채용예정시기</span>
                                <div class="schedule_add_box add_box"
                                    v-if="detailsPut && this.$store.state.loginUser.authority === 9">
                                    <select v-model="schedulesAddOption">
                                        <!-- <option value="202206">6월</option>
                                        <option value="202207">7월</option> -->
                                        <option value="202208">8월</option>
                                        <option value="202209">9월</option>
                                        <option value="202210">10월</option>
                                        <option value="202211">11월</option>
                                    </select>
                                    <span class="badge bg-success" style="margin-left: 5px; cursor: pointer;"
                                        @click="schedulesAddBtn">
                                        <i class="fa fa-plus"></i>
                                    </span>
                                </div>
                            </th>
                            <th scope="col">
                                <span>채용예정직군</span>
                                <div class="schedule_add_box add_box"
                                    v-if="detailsPut && this.$store.state.loginUser.authority === 9">
                                    <input type="text" style="width: 15%" v-model="jobgroupAddText"
                                        @keyup.enter="jobgroupAddBtn">
                                    <span class="badge bg-success" style="margin-left: 5px; cursor: pointer;"
                                        @click="jobgroupAddBtn">
                                        <i class="fa fa-plus"></i>
                                    </span>

                                </div>
                            </th>
                        </tr>
                        <tr>
                            <td>
                                <span class="badge bg-secondary" :style="detailsPut ? 'cursor: pointer;' : false"
                                    v-for="(schedule, index) in schedulesArr" :key="schedule"
                                    @click="deleteScheduls(index)">{{
                                    schedule }}</span>
                            </td>
                            <td>
                                <span class="badge bg-secondary" :style="detailsPut ? 'cursor: pointer;' : false"
                                    v-for="(jobgroup, index) in jobgroupArr" :key="jobgroup"
                                    @click="deleteJobgroup(index)">{{
                                    jobgroup }}</span>
                            </td>
                        </tr>
                    </thead>
                </table>

                <table class="table text-center">
                    <thead>
                        <tr>
                            <th scope="col" colspan="6">채용지원솔루션</th>
                        </tr>
                        <tr>
                            <td>채용계획검토</td>
                            <td>직무분석</td>
                            <td>서류전형 도구</td>
                            <td>면접전형 도구</td>
                            <td>평가 도구</td>
                            <td>채용페이지 지원</td>
                        </tr>
                        <tr v-if="detailsPut && this.$store.state.loginUser.authority === 9">
                            <td>
                                <input type="checkbox" ref="srp" :checked="userData.srp ? true : false"
                                    @click="solutionCheckBox">
                            </td>
                            <td>
                                <input type="checkbox" ref="sja" :checked="userData.sja ? true : false"
                                    @click="solutionCheckBox">
                            </td>
                            <td>
                                <input type="checkbox" ref="spt" :checked="userData.spt ? true : false"
                                    @click="solutionCheckBox">
                            </td>
                            <td>
                                <input type="checkbox" ref="sit" :checked="userData.sit ? true : false"
                                    @click="solutionCheckBox">
                            </td>
                            <td>
                                <input type="checkbox" ref="set" :checked="userData.set ? true : false"
                                    @click="solutionCheckBox">
                                <span v-if="userData.set" style="margin-left: 20px;">
                                    <input type="number" v-model="userData.setcnt" style="width: 25%;" min="1" max="9">
                                </span>
                            </td>
                            <td>
                                <input type="checkbox" ref="saf" :checked="userData.saf ? true : false"
                                    @click="solutionCheckBox">
                                <span v-if="userData.saf" style="margin-left: 20px;">
                                    <input type="number" v-model="userData.safcnt" style="width: 20%;" min="1" max="9">
                                </span>
                            </td>
                        </tr>
                        <tr v-else>
                            <td>
                                <i class="fa fa-solid fa-check" v-if="userData.srp"></i>
                            </td>
                            <td>
                                <i class="fa fa-solid fa-check" v-if="userData.sja"></i>
                            </td>
                            <td>
                                <i class="fa fa-solid fa-check" v-if="userData.spt"></i>
                            </td>
                            <td>
                                <i class="fa fa-solid fa-check" v-if="userData.sit"></i>
                            </td>
                            <td>
                                <span v-if="userData.saf">
                                    {{ userData.set ? userData.setcnt : '' }}
                                </span>
                            </td>
                            <td>
                                <span v-if="userData.saf">
                                    {{ userData.safcnt }}
                                </span>
                            </td>
                        </tr>
                    </thead>
                </table>

                <table class="table text-center">
                    <thead>
                        <tr>
                            <th scope="col" colspan="4"
                                v-if="detailsPut && this.$store.state.loginUser.authority === 9">
                                진행단계<br>
                                <small style="color: #6666e1;;">내용을 누르면 진행도가 변경 됩니다.</small>
                            </th>
                            <th scope="col" colspan="4" v-else>진행단계</th>
                            <th>마감일</th>
                        </tr>
                        <tr>
                            <td style="cursor: pointer;"
                                v-if="detailsPut && this.$store.state.loginUser.authority === 9"
                                @click="userData.ingstep = 1">요구사항 수집</td>
                            <td v-else>요구사항 수집</td>
                            <td style="cursor: pointer;"
                                v-if="detailsPut && this.$store.state.loginUser.authority === 9"
                                @click="userData.ingstep = 2">분석 및 진단</td>
                            <td v-else>분석 및 진단</td>
                            <td style="cursor: pointer;"
                                v-if="detailsPut && this.$store.state.loginUser.authority === 9"
                                @click="userData.ingstep = 3">현장지원</td>
                            <td v-else>현장지원</td>
                            <td style="cursor: pointer;"
                                v-if="detailsPut && this.$store.state.loginUser.authority === 9"
                                @click="userData.ingstep = 9">사후관리 및 종료</td>
                            <td v-else>
                                사후관리 및 종료
                                <button class="btn" :class="userData.surveyflag === 'Y' ? 'btn-secondary' : 'btn-info'"
                                    style="padding: 5px 10px; margin: 0 0 0 15px;" @click="satisfactionBtn"
                                    v-if="userData.ingstep >= 9">만족도 조사</button>
                                <Satisfaction v-if="satisfaction" @exit="satisfactionBtn" :userData="userData">
                                </Satisfaction>
                                <SucessSatisfaction v-if="sucessSatisfaction" @exit="satisfactionBtn" :satisfactionData="sucessSatisfactionData" />
                            </td>
                            <td rowspan="2" style="vertical-align: middle;"
                                v-if="detailsPut && this.$store.state.loginUser.authority === 9">
                                <input type="text" placeholder="20220101" v-model="userData.closingdt">
                            </td>
                            <td rowspan="2" style="vertical-align: middle;" v-else>{{ userData.closingdt }}
                            </td>
                        </tr>
                        <tr>
                            <td colspan="4">
                                <div class="progress" style="width: 90%; height: 10px; margin: 0 auto;">
                                    <div class="progress-bar progress-bar-striped progress-bar-animated bg-success"
                                        role="progressbar" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"
                                        style="height: 13px;"
                                        :style="userData.ingstep === 1 ? 'width: 10%;' : userData.ingstep === 2 ? 'width: 40%;' : userData.ingstep === 3 ? 'width: 65%;' : userData.ingstep === 9 ? 'width: 100%;' : 'width: 0;'">
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </thead>
                </table>

                <table class="table text-center">
                    <thead>
                        <tr>
                            <th scope="col" colspan="4">산출물</th>
                        </tr>
                        <tr>
                            <td>요구사항 수집</td>
                            <td>분석 및 진단</td>
                            <td>
                                현장지원
                            </td>
                            <td>사후관리 및 종료</td>
                        </tr>
                        <tr>
                            <td>
                                <small
                                    style="position: relative; width: 100%; display: flex; justify-content: space-between;"
                                    v-for="file in fileData" :key="file">
                                    <a :href="`./download/?path=${file.file_save_name}&fname=${file.file_origin_name}`"
                                        :download="file.file_origin_name" v-if="file.ingstep === 1"
                                        :title="file.file_origin_name">
                                        <font v-if="file.file_origin_name.length >= 17">{{
                                        file.file_origin_name.substr(0, 17) + ' ···' }}</font>
                                        <font v-else>{{ file.file_origin_name }}</font>
                                    </a>
                                    <div v-if="file.ingstep === 1">
                                        <p style="margin-right: 30px;">{{ file.createdt.substr(0, 10) }}</p>
                                        <i class="fas fa-times" style="font-size: 20px;" @click="deleteFileBtn(file)"
                                            v-if="userMe === file.createuser || this.$store.state.loginUser.authority === 9"></i>
                                    </div>
                                </small>

                                <input type="file" class="testFile" @change="fileUploads(1, 1)"
                                    v-if="this.$store.state.loginUser.authority === 1 || this.$store.state.loginUser.authority === 9">
                            </td>
                            <td>
                                <small
                                    style="position: relative; width: 100%; display: flex; justify-content: space-between;"
                                    v-for="file in fileData" :key="file">
                                    <a :href="`./download/?path=${file.file_save_name}&fname=${file.file_origin_name}`"
                                        :download="file.file_origin_name" v-if="file.ingstep === 2"
                                        :title="file.file_origin_name">
                                        <font v-if="file.file_origin_name.length >= 17">{{
                                        file.file_origin_name.substr(0, 17) + ' ···' }}</font>
                                        <font v-else>{{ file.file_origin_name }}</font>
                                    </a>
                                    <div v-if="file.ingstep === 2">
                                        <p style="margin-right: 30px;">{{ file.createdt.substr(0, 10) }}</p>
                                        <i class="fas fa-times" style="font-size: 20px;"
                                            @click="deleteFileBtn(file)"></i>
                                    </div>
                                </small>

                                <input type="file" class="testFile" @change="fileUploads(2, 2)"
                                    v-if="this.$store.state.loginUser.authority === 1 || this.$store.state.loginUser.authority === 9">
                            </td>
                            <td>
                                <small
                                    style="position: relative; width: 100%; display: flex; justify-content: space-between;"
                                    v-for="file in fileData" :key="file">
                                    <a :href="`./download/?path=${file.file_save_name}&fname=${file.file_origin_name}`"
                                        :download="file.file_origin_name" v-if="file.ingstep === 3"
                                        :title="file.file_origin_name">
                                        <font v-if="file.file_origin_name.length >= 17">{{
                                        file.file_origin_name.substr(0, 17) + ' ···' }}</font>
                                        <font v-else>{{ file.file_origin_name }}</font>
                                    </a>
                                    <div v-if="file.ingstep === 3">
                                        <p style="margin-right: 30px;">{{ file.createdt.substr(0, 10) }}</p>
                                        <i class="fas fa-times" style="font-size: 20px;"
                                            @click="deleteFileBtn(file)"></i>
                                    </div>
                                </small>

                                <input type="file" class="testFile" @change="fileUploads(3, 3)"
                                    v-if="this.$store.state.loginUser.authority === 1 || this.$store.state.loginUser.authority === 9">
                            </td>
                            <td>
                                <small
                                    style="position: relative; width: 100%; display: flex; justify-content: space-between;"
                                    v-for="file in fileData" :key="file">
                                    <a :href="`./download/?path=${file.file_save_name}&fname=${file.file_origin_name}`"
                                        :download="file.file_origin_name" v-if="file.ingstep === 9"
                                        :title="file.file_origin_name">
                                        <font v-if="file.file_origin_name.length >= 17">{{
                                        file.file_origin_name.substr(0, 17) + ' ···' }}</font>
                                        <font v-else>{{ file.file_origin_name }}</font>
                                    </a>
                                    <div v-if="file.ingstep === 9">
                                        <p style="margin-right: 30px;">{{ file.createdt.substr(0, 10) }}</p>
                                        <i class="fas fa-times" style="font-size: 20px;"
                                            @click="deleteFileBtn(file)"></i>
                                    </div>
                                </small>

                                <input type="file" class="testFile" @change="fileUploads(9, 4)"
                                    v-if="this.$store.state.loginUser.authority === 1 || this.$store.state.loginUser.authority === 9">
                            </td>
                        </tr>
                    </thead>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import { getFiles, getUserDetail, petchUserState, putUserDetail, userFileUploads, deleteFile, getSatisfaction } from '../../api/index';
import Satisfaction from '../../examples/Satisfaction.vue';
import SucessSatisfaction from '../../examples/SucessSatisfaction.vue';

export default {
    components: {
        Satisfaction,
        SucessSatisfaction
    },
    props: {
        appcode: {
            type: String,
        },
    },
    created () {
        const userData = {
            "token": this.$store.state.token,
            "appcode": this.appcode
        }

        // 유저 상세 정보 API
        getUserDetail(userData)
            .then (response => {
                this.userData = response.data;

                this.organizeArr();
            })
            .catch (err => {
                console.log(err);
            })
        
        this.getFile();

        this.$store.state.appcode = this.appcode;

        this.userMe = this.$store.state.loginUser.companycode + this.$store.state.loginUser.workercode;
    },
    data() {
        return {
            userData: [],
            detailsPut: false,
            schedulesArr: [],
            jobgroupArr: [],
            solutionArr: [],
            schedulesAddOption: '',
            jobgroupAddText: '',
            closeDate: '',
            fileData: [],
            satisfaction: false,
            sucessSatisfaction: false,
            sucessSatisfactionData: {},
            userMe: '',
            beforehandArr: []
        }
    },
    updated () {
        this.userData.schedules = '';
        for(let i = 0; i < this.schedulesArr.length; i++) {
            this.userData.schedules += this.schedulesArr[i] + '_/';
        }

        this.userData.jobgroup = '';
        for (let i = 0; i < this.jobgroupArr.length; i++) {
            this.userData.jobgroup += this.jobgroupArr[i] + '_/';
        }

        this.userData.solution = '';
        for (let i = 0; i < this.solutionArr.length; i++) {
            this.userData.solution += this.solutionArr[i] + '_/';
        }

        this.userData.solution = '';
        
        if (this.userData.srp) {
            this.userData.solution += 'RP_/';
        }

        if (this.userData.sja) {
            this.userData.solution += 'JA_/';
        }

        if (this.userData.spt) {
            this.userData.solution += 'PT_/';
        }

        if (this.userData.sit) {
            this.userData.solution += 'IT_/';
        }

        if (this.userData.saf) {
            this.userData.solution += 'AF_/';
            this.userData.solution += this.userData.safcnt + '_/';
        }

        if (this.userData.set) {
            this.userData.solution += 'ET_/';
            this.userData.solution += this.userData.setcnt + '_/';
        }

        for (let i = 0; i < this.beforehandArr.length; i++) {
            if (this.beforehandArr[i] === 'Y') {
                this.beforehandArr[i] = true;
            } else if (this.beforehandArr[i] === 'N') {
                this.beforehandArr[i] = false;
            }
        }
    },
    methods: {
        detaileExit() {
            this.$emit('exit');
        },
        detailsPutClick() {
            if (this.detailsPut) {
                
                const detailsPutConfirm = confirm('변경 하시겠습니까?');

                if (detailsPutConfirm) {
                    if (this.userData.saf) {
                        if (!this.userData.safcnt) {
                            alert('채용페이지 지원은 최소 1 이상이어야 합니다.');
                            return;
                        }
                    }

                    if (this.userData.set) {
                        if (!this.userData.setcnt) {
                            alert('평가 도구는 최소 1 이상이어야 합니다.');
                            return;
                        }
                    }

                    this.userData.beforehand = '';
                    for (let before of this.beforehandArr) {
                        if (before) {
                            this.userData.beforehand += 'Y_/';
                        } else {
                            this.userData.beforehand += 'N_/';
                        }
                    }

                    const userData = {
                        token: this.$store.state.token,
                        appcode: this.appcode,
                        schedule: this.userData.schedules,
                        jobgroup: this.userData.jobgroup,
                        solution: this.userData.solution,
                        ingstep: this.userData.ingstep,
                        closingdt: this.userData.closingdt,
                        bigo: this.userData.bigo,
                        beforehand: this.userData.beforehand,
                    }

                    putUserDetail(userData)
                        .then(response => {
                            alert(response.data.resultmsg);
                            this.detailStateFetch();
                        })
                        .catch(err => {
                            console.log(err);
                        })
                } else {
                    this.beforehandArr = [];

                    this.beforehandArr = this.userData.beforehand.split('_/');
                    this.beforehandArr.pop();

                    for (let i = 0; i < this.beforehandArr.length; i++) {
                        if (this.beforehandArr[i] === 'Y') {
                            this.beforehandArr[i] = true;
                        } else if (this.beforehandArr[i] === 'N') {
                            this.beforehandArr[i] = false;
                        }
                    }
                }

                this.detailsPut = false;
            } else {
                this.detailsPut = true;
            }
        },
        detailStateFetch() {
            const userData = {
                token: this.$store.state.token,
                appcode: this.appcode,
                chgcode: this.userData.appstate
            }

            petchUserState(userData)
                .then(response => {
                    console.log(response.data);
                })
                .catch(err => {
                    console.log(err);
                })
        },
        organizeArr() {
            this.schedulesArr = this.userData.schedules.split('_/');
            this.schedulesArr.pop();

            this.jobgroupArr = this.userData.jobgroup.split('_/');
            this.jobgroupArr.pop();

            this.solutionArr = this.userData.solution.split('_/');
            this.solutionArr.pop();

            this.beforehandArr = this.userData.beforehand.split('_/');
            this.beforehandArr.pop();
        },
        schedulesAddBtn() {
            let scheduleBol = true;

            for (let schedules of this.schedulesArr) {
                if (schedules === this.schedulesAddOption) {
                    scheduleBol = false;
                    break;
                } else {
                    scheduleBol  = true;
                }
            }

            if (scheduleBol) {
                this.schedulesArr.push(this.schedulesAddOption);

                this.schedulesAddOption = '';
            } else {
                alert('이미 있습니다.');

                this.schedulesAddOption = '';
            }
        },
        jobgroupAddBtn() {
            let jobgroupBol = true;

            for (let jobgroup of this.jobgroupArr) {
                if (jobgroup === this.jobgroupAddText) {
                    jobgroupBol = false;
                    break;
                } else {
                    jobgroupBol = true;
                }
            }

            if (jobgroupBol) {
                this.jobgroupArr.push(this.jobgroupAddText);

                this.jobgroupAddText = '';
            } else {
                alert('이미 있습니다.');
                
                this.jobgroupAddText = '';
            }
        },
        solutionCheckBox() {
            const srpCheck = this.$refs.srp.checked;
            const sjaCheck = this.$refs.sja.checked;
            const sptCheck = this.$refs.spt.checked;
            const sitCheck = this.$refs.sit.checked;
            const setCheck = this.$refs.set.checked;
            const safCheck = this.$refs.saf.checked;

            if (srpCheck) {
                this.userData.srp = 1;
            } else {
                this.userData.srp = 0;
            }

            if (sjaCheck) {
                this.userData.sja = 1;
            } else {
                this.userData.sja = 0;
            }

            if (sptCheck) {
                this.userData.spt = 1;
            } else {
                this.userData.spt = 0;
            }

            if (sitCheck) {
                this.userData.sit = 1;
            } else {
                this.userData.sit = 0;
            }

            if (setCheck) {
                this.userData.set = 1;
            } else {
                this.userData.set = 0;
                this.userData.setcnt = 0;
            }

            if (safCheck) {
                this.userData.saf = 1;
            } else {
                this.userData.saf = 0;
                this.userData.safcnt = 0;
            }
        },
        deleteScheduls(index) {
            if (this.detailsPut && this.$store.state.loginUser.authority === 9) {
                this.schedulesArr.splice(index, 1);
            }
        },
        deleteJobgroup(index) {
            if (this.detailsPut) {
                this.jobgroupArr.splice(index, 1);
            }
        },
        getFile() {
            const userData = {
                // ingstep: 1,
                appcode: this.appcode,
                token: this.$store.state.token,
            }

            getFiles(userData)
                .then(response => {
                    this.fileData = response.data;
                })
                .catch(err => {
                    console.log(err);
                })
        },
        fileUploads(ingstepNum, idx) {
            const formData = new FormData();
            const file = document.querySelectorAll('.testFile')[idx - 1].files[0];
            formData.append('file_name', file);
            formData.append('ingstep', ingstepNum);
            formData.append('appcode', this.appcode);
            formData.append('createuser', this.$store.state.loginUser.companycode + this.$store.state.loginUser.workercode);

            const userData = {
                appcode: this.appcode,
                token: this.$store.state.token,
            }

            // 파일 업로드 API
            userFileUploads(formData, userData)
                .then(response => {
                    console.log(response.data);
                    this.getFile();
                })
                .catch(err => {
                    console.log(err);
                })
        },
        deleteFileBtn(fileData) {
            const fileDelete = confirm('정말 삭제하시겠습니까?');
            
            if(fileDelete) {
                const userData = {
                    appcode: this.appcode,
                    ingstep: fileData.ingstep,
                    fileseq: fileData.fileseq,
                    filepath: fileData.file_save_name
                }
    
                const token = this.$store.state.token;
    
                deleteFile(userData, token)
                    .then(() => {
                        alert('파일이 삭제되었습니다.');
                        
                        this.getFile();
                    })
                    .catch(err => {
                        console.log(err);
                    })
            }
        },
        satisfactionBtn() {
            if (this.userData.surveyflag === 'Y') {
                if (this.$store.state.loginUser.authority >= 5) {
                    const data = {
                        code: this.appcode,
                        token: this.$store.state.token,
                    }
    
                    getSatisfaction(data)
                        .then(res => {
                            console.log(res);
                            this.sucessSatisfactionData = res.data;
                            this.sucessSatisfaction = !this.sucessSatisfaction;
                        })
                        .catch(err => {
                            if (err.response.data) alert(err.response.data);
    
                            throw new Error(err);
                        })
                } else {
                    alert('읽기 권한이 없습니다.');
                }
            } else {
                if(this.satisfaction) {
                    this.satisfaction = false;

                    const userData = {
                        "token": this.$store.state.token,
                        "appcode": this.appcode
                    }

                    // 유저 상세 정보 API
                    getUserDetail(userData)
                        .then(response => {
                            this.userData = response.data;

                            this.organizeArr();
                        })
                        .catch(err => {
                            console.log(err);
                        })

                    this.getFile();

                    this.$store.state.appcode = this.appcode;

                    this.userMe = this.$store.state.loginUser.companycode + this.$store.state.loginUser.workercode;
                } else {
                    this.satisfaction = true;
                }
            }
        },
    },
}
</script>

<style scoped>
.table {
    border: 1px solid #e9ecef;
    margin-bottom: 50px;
}

th {
    background-color: #eee !important;
}

th, td {
    border: 1px solid #e9ecef;
}

.details_btn_box {
    width: 100%;
    margin-top: 30px;
}

.details_btn_box button {
    float: right;
}

.fa-times {
    font-size: 40px;
    position: absolute;
    top: 2%;
    right: 2%;
    cursor: pointer;
}

.details {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    transition-duration: 0.5s;
}

.details::before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: 0.5;
    background-color: black;
    z-index: -1;
}

.details_box {
    width: 85%;
    height: 80%;
    border-radius: 10px;
    overflow-y: scroll;
    position: relative;
    padding: 60px;
    top: 50%;
    left: 50%;
    margin-top: calc(-80% / 3.5);
    margin-left: calc(-85% / 2);
    color: black;
    background-color: white;
}

.bg-secondary {
    margin: 0 5px;
}

.bg-secondary:last-child {
    margin-right: 0;
}

.detail_bigo_textarea {
    width: 100%;
    height: 100px;
    resize: none;
}

td input[type="file"] {
    width: 100%;
}

.preliminary_investigation {
    margin: 30px 0;
}

.preliminary_investigation ul, .preliminary_investigation li {
    list-style: none;
}

.preliminary_investigation_li span {
    margin-right: 20px;
}
</style>