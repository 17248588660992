<template>
    <div class="satisfaction_box">
        <div class="satisfaction_in_box">
            <i class="fas fa-times" @click="this.$emit('exit')"></i>
            <div class="satisfaction_tit">
                <h3>2022년 기업 채용 현장지원 사업 참여기업</h3>
                <h3>사업 만족도 설문조사</h3>
            </div>
            <p class="satisfaction_sup">
                본 설문조사는 한국산업인력공단이 주관하고,엑스퍼트컨설팅이 운영하는
                ｢2022년 기업 채용 현장지원 사업(이하‘현장지원’)｣에 대한 만족도 조사입니다.<br>
                제공해주시는 정보는 관련 서비스 개선의 용도로만 활용될 예정이오니
                기관 및 기업 담당자님께서는 적극적으로 답변해주시면 감사하겠습니다.
            </p>
            <ul>
                <li class="satisfaction_problem" :idx="1">
                    <h5>1. '현장지원'을 알게 된 경로는 무엇입니까?</h5>
                    <p value="1_/" @click="answerOne">① NCS 홈페이지</p>
                    <p value="2_/" @click="answerOne">② 지인 소개</p>
                    <p value="3_/" @click="answerOne">③ 운영기관의 안내(이메일, 전화 방문 등)</p>
                    <p value="4_/" @click="answerOne">④ 정부기관이나 기타 관련단체의 안내</p>
                    <p value="5_/" @click="answerOne">⑤ 광고 등 홍보물(배너, SNS, 유튜브 등)</p>
                    <p value="6_/" @click="answerOne" style="display: inline-block;">⑥ 기타</p>
                    <input type="text" class="etc_inp" maxlength="500" v-if="answer01 === '6_/'" v-model="etc01">
                </li>
                <li class="satisfaction_problem" :idx="2">
                    <h5>2. '현장지원' 에 대한 만족도는 어떠합니까?</h5>
                    <p value="1_/" @click="answerTwo">① 매우 높다</p>
                    <p value="2_/" @click="answerTwo">② 높다</p>
                    <p value="3_/" @click="answerTwo">③ 보통이다</p>
                    <p value="4_/" @click="answerTwo">④ 낮다</p>
                    <p value="5_/" @click="answerTwo">⑤ 매우 낮다</p>
                </li>
                <li class="satisfaction_problem" :idx="3">
                    <h5>
                        3. '현장지원' 에 참여하시기 전, 아래 중 해당하는 사항에 대해 모두 응답해주십시오.<br>
                        (복수응답 가능)
                    </h5>
                    <p value="1_/" @click="answerThree">① NCS 기반 능력중심 채용의 개념에 대해서 알고 있었음</p>
                    <p value="2_/" @click="answerThree">② 공정채용(블라인드 채용)의 개념에 대해서 알고 있었음</p>
                    <p value="3_/" @click="answerThree">③ 상기 채용형태에 대해서 들어봤지만 구체적인 개념은 몰랐음</p>
                    <p value="4_/" @click="answerThree">④ 들어본 적 없음</p>
                    <p value="5_/" @click="answerThree" style="display: inline-block;">⑤ 기타</p>
                    <input type="text" class="etc_inp" maxlength="500" v-if="answer03 === '5_/'" v-model="etc03">
                </li>
                <li class="satisfaction_problem" :idx="4">
                    <h5>4. '현장지원' 이 NCS 기반 능력중심 채용·공정채용에 대한 이해를 높이는 데에 도움이 되었습니까?</h5>
                    <p value="1_/" @click="answerFour">① 매우 그렇다</p>
                    <p value="2_/" @click="answerFour">② 그렇다</p>
                    <p value="3_/" @click="answerFour">③ 보통이다</p>
                    <p value="4_/" @click="answerFour">④ 그렇지 않다</p>
                    <p value="5_/" @click="answerFour">⑤ 전혀 그렇지 않다</p>
                </li>
                <li class="satisfaction_problem" :idx="5">
                    <h5>5. '현장지원' 이 채용업무 부담 및 채용비용 절감에 도움이 되었습니까?</h5>
                    <p value="1_/" @click="answerFive">① 매우 그렇다</p>
                    <p value="2_/" @click="answerFive">② 그렇다</p>
                    <p value="3_/" @click="answerFive">③ 보통이다</p>
                    <p value="4_/" @click="answerFive">④ 그렇지 않다</p>
                    <p value="5_/" @click="answerFive">⑤ 전혀 그렇지 않다</p>
                </li>
                <li class="satisfaction_problem" :idx="6">
                    <h5>6. '현장지원' 이 기존의 채용절차를 개선하는 데에 도움이 되었습니까?</h5>
                    <p value="1_/" @click="answerSix">① 매우 그렇다</p>
                    <p value="2_/" @click="answerSix">② 그렇다</p>
                    <p value="3_/" @click="answerSix">③ 보통이다</p>
                    <p value="4_/" @click="answerSix">④ 그렇지 않다</p>
                    <p value="5_/" @click="answerSix">⑤ 전혀 그렇지 않다</p>
                </li>
                <li class="satisfaction_problem" :idx="7">
                    <h5>7. (5의 ① ~ ③에 답한 경우) 사업 과정 중, 어떤 부분이 채용절차를 개선하는 데에 가장 도움이 되었습니까?</h5>
                    <p value="1_/" @click="answerSeven">① 직무능력중심 채용계획 수립</p>
                    <p value="2_/" @click="answerSeven">② 전형별 평가도구 개발 및 활용</p>
                    <p value="3_/" @click="answerSeven">③ 채용 프로세스 전반 체계화</p>
                    <p value="4_/" @click="answerSeven">④ 채용업무 부담 및 비용 절감</p>
                    <p value="5_/" @click="answerSeven" style="display: inline-block;">⑤ 기타</p>
                    <input type="text" class="etc_inp" maxlength="500" v-if="answer07 === '5_/'" v-model="etc07">
                </li>
                <li class="satisfaction_problem" :idx="8">
                    <h5>8. (5의 ④ ~ ⑤에 답한 경우) 귀사의 채용절차를 개선하는 데에 본 사업이 도움이 되기 위해서, 사업 과정 중 개선이 필요한 부분이 무엇이라고 생각합니까?</h5>
                    <p value="1_/" @click="answerEight">① 지원 범위 확대</p>
                    <p value="2_/" @click="answerEight">② 평가도구의 현장 활용도 제고</p>
                    <p value="3_/" @click="answerEight">③ 평가도구 활용방법 관련 교육</p>
                    <p value="4_/" @click="answerEight">④ 평가도구의 다양화</p>
                    <p value="5_/" @click="answerEight" style="display: inline-block;">⑤ 기타</p>
                    <input type="text" class="etc_inp" maxlength="500" v-if="answer08 === '5_/'" v-model="etc08">
                </li>
                <li class="satisfaction_problem" :idx="9">
                    <h5>9. '현장지원' 에 참여하기 전, 기존에 활용하셨던 채용도구를 모두 표기해주시기바랍니다. (복수응답 가능)</h5>
                    <p>
                        <span class="answernineEl" @click="answernine('1_/', 0)">① 직무기술서</span>
                        <span class="answernineEl" @click="answernine('2_/', 1)">② 채용 공고문</span>
                        <span class="answernineEl" @click="answernine('3_/', 2)">③ 입사지원서</span>
                        <span class="answernineEl" @click="answernine('4_/', 3)">④ 자기소개서</span>
                    </p>
                    <p>
                        <span class="answernineEl" @click="answernine('5_/', 4)">⑤ 경험·경력기술서</span>
                        <span class="answernineEl" @click="answernine('6_/', 5)">⑥ 필기전형(필기문항)</span>
                        <span class="answernineEl" @click="answernine('7_/', 6)">⑦ 면접전형(면접문항)</span>
                        <span class="answernineEl" @click="answernine('8_/', 7)">⑧ 온라인 채용접수페이지</span>
                    </p>
                    <p>
                        <span class="answernineEl" @click="answernine('9_/', 8)">⑨ 기타 </span>
                        <input type="text" class="etc_inp" maxlength="500" v-model="etc09" v-if="answerNineArr[8]">
                    </p>
                </li>
                <li class="satisfaction_problem" :idx="10">
                    <h5>10. 아래 표의 왼쪽(1, 2열)에 지원 대상 직무 개수 및 채용(예정) 인원을 기입해 주시고,<br>
                        지원받는 사항에 대한 만족도(직무능력과 관련성이 높은 사항을 검증하는데 도움이 된 정도)<br>
                        를 "1~5점" 까지 표기해 주십시오.</h5>
                    <p><strong>* 만족도 점수: 5(매우 만족), 4(만족), 3(보통), 2(불만족), 1(매우 불만족), 해당없음</strong></p>
                    <table class="table">
                        <thead>
                            <th>직무 개수</th>
                            <th>채용(예정)인원</th>
                            <th>채용계획검토</th>
                            <th>평가도구검토</th>
                            <th>직무분석</th>
                            <th v-if="userData.set">서류전형 평가도구</th>
                            <th>면접전형 평가도구</th>
                            <th v-if="userData.saf">채용접수 페이지</th>
                        </thead>
                        <tbody class="table-group-divider">
                            <tr>
                                <td>{{ answerTanArr[0] }}</td>
                                <td><input type="number" min="0" max="5" placeholder="0" v-model="answerTanArr[1]"></td>
                                <td><input type="number" min="0" max="5" placeholder="0" v-model="answerTanArr[2]"></td>
                                <td><input type="number" min="0" max="5" placeholder="0" v-model="answerTanArr[3]"></td>
                                <td><input type="number" min="0" max="5" placeholder="0" v-model="answerTanArr[4]"></td>
                                <td v-if="userData.set">
                                    <input type="number" min="0" max="5" placeholder="0" v-model="answerTanArr[5]">
                                </td>
                                <td><input type="number" min="0" max="5" placeholder="0" v-model="answerTanArr[6]"></td>
                                <td v-if="userData.saf">
                                    <input type="number" min="0" max="5" placeholder="0" v-model="answerTanArr[7]">
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </li>
                <li class="satisfaction_problem" :idx="11">
                    <h5>11. 본 사업과 관련하여 개선되었으면 하는 사항 등 기타 의견이 있으시다면 자유롭게 기재해주십시오.</h5>
                    <textarea cols="160" rows="50" v-model="answer11"></textarea>
                </li>
                <button class="btn btn-success" style="width: 20%; height: 50px;" @click="answerPost">제출하기</button>
            </ul>
        </div>
    </div>
</template>

<script>
import { postSatisfaction } from '../api/index';

export default {
    props: {
        userData: {
            type: Array,
        },
    },
    data() {
        return {
            answer01: "",
            answer02: "",
            answer03: "",
            answer04: "",
            answer05: "",
            answer06: "",
            answer07: "",
            answer08: "",
            answer09: "",
            answer10: "",
            answer11: "",
            etc01: "",
            etc03: "",
            etc07: "",
            etc08: "",
            etc09: "",
            answerNineArr: [false, false, false, false, false, false, false, false, false],
            answerTanArr: [0, 0, 0, 0, 0, 0, 0, 0],
            solutionArr: [],
        }
    },
    mounted () {
        this.solutionArr = this.userData.jobgroup.split('_/');
        this.solutionArr.pop();

        this.answerTanArr[0] = this.solutionArr.length;
    },
    updated () {
        if (this.answer01 !== '6_/') {
            this.etc01 = '';
        }

        if (this.answer03 !== '5_/') {
            this.etc03 = '';
        }

        if (this.answer07 !== '5_/') {
            this.etc07 = '';
        }

        if (this.answer08 !== '5_/') {
            this.etc08 = '';
        }

        if (!this.answerNineArr[8]) {
            this.etc09 = '';
        }
        
        this.answer10 = '';

        for(let tan of this.answerTanArr) {
            this.answer10 += tan + '_/';
        }
    },
    methods: {
        answerOne(e) {
            const answerEl01 = document.querySelectorAll('.satisfaction_problem[idx="1"] p');

            for (let answer of answerEl01) {
                answer.style.fontWeight = 'normal';
            }

            e.target.style.fontWeight = 'bold';

            this.answer01 = e.target.getAttribute('value');
        },
        answerTwo(e) {
            const answerEl02 = document.querySelectorAll('.satisfaction_problem[idx="2"] p');

            for (let answer of answerEl02) {
                answer.style.fontWeight = 'normal';
            }

            e.target.style.fontWeight = 'bold';

            this.answer02 = e.target.getAttribute('value');
        },
        answerThree(e) {
            const answerEl03 = document.querySelectorAll('.satisfaction_problem[idx="3"] p');

            for (let answer of answerEl03) {
                answer.style.fontWeight = 'normal';
            }

            e.target.style.fontWeight = 'bold';

            this.answer03 = e.target.getAttribute('value');
        },
        answerFour(e) {
            const answerEl04 = document.querySelectorAll('.satisfaction_problem[idx="4"] p');

            for (let answer of answerEl04) {
                answer.style.fontWeight = 'normal';
            }

            e.target.style.fontWeight = 'bold';

            this.answer04 = e.target.getAttribute('value');
        },
        answerFive(e) {
            const answerEl05 = document.querySelectorAll('.satisfaction_problem[idx="5"] p');

            for (let answer of answerEl05) {
                answer.style.fontWeight = 'normal';
            }

            e.target.style.fontWeight = 'bold';

            this.answer05 = e.target.getAttribute('value');

            if (this.answer05 === '4_/' || this.answer05 === '5_/') {
                const answerEl07 = document.querySelectorAll('.satisfaction_problem[idx="7"] p');

                for (let answer of answerEl07) {
                    answer.style.fontWeight = 'normal';
                }

                this.answer07 = '';
                this.etc07 = '';
            }

            if (this.answer05 === '1_/' || this.answer05 === '2_/' || this.answer05 === '3_/') {
                const answerEl08 = document.querySelectorAll('.satisfaction_problem[idx="8"] p');

                for (let answer of answerEl08) {
                    answer.style.fontWeight = 'normal';
                }

                this.answer08 = '';
                this.etc08 = '';
            }
        },
        answerSix(e) {
            const answerEl06 = document.querySelectorAll('.satisfaction_problem[idx="6"] p');

            for (let answer of answerEl06) {
                answer.style.fontWeight = 'normal';
            }

            e.target.style.fontWeight = 'bold';

            this.answer06 = e.target.getAttribute('value');
        },
        answerSeven(e) {
            if (this.answer05 === '1_/' || this.answer05 === '2_/' || this.answer05 === '3_/') {
                const answerEl07 = document.querySelectorAll('.satisfaction_problem[idx="7"] p');

                for (let answer of answerEl07) {
                    answer.style.fontWeight = 'normal';
                }

                e.target.style.fontWeight = 'bold';

                this.answer07 = e.target.getAttribute('value');
            } else {
                alert('5의 ① ~ ③에 답한 경우에만 선택 하실 수 있습니다.');
            }
        },
        answerEight(e) {
            if (this.answer05 === '4_/' || this.answer05 === '5_/') {
                const answerEl08 = document.querySelectorAll('.satisfaction_problem[idx="8"] p');

                for (let answer of answerEl08) {
                    answer.style.fontWeight = 'normal';
                }

                e.target.style.fontWeight = 'bold';

                this.answer08 = e.target.getAttribute('value');
            } else {
                alert('5의 ④ ~ ⑤에 답한 경우에만 선택 하실 수 있습니다.');
            }
        },
        answernine(value, index) {
            const answerNineElement = document.querySelectorAll('.answernineEl');

            answerNineElement[index].classList.toggle('bold_text');
            
            if (this.answerNineArr[index]) {
                this.answerNineArr[index] = false;
            } else {
                this.answerNineArr[index] = value;
            }

            this.answer09 = '';

            for (let answer of this.answerNineArr) {
                if (answer) {
                    this.answer09 += answer;
                }
            }
        },
        answerPost() {
            const conFirm = confirm('만족도 조사를 제출 하시겠습니까?');

            if (conFirm) {
                const userData = {
                    token: this.$store.state.token,
                    appcode: this.$store.state.appcode,
                    answer01: this.answer01,
                    answer02: this.answer02,
                    answer03: this.answer03,
                    answer04: this.answer04,
                    answer05: this.answer05,
                    answer06: this.answer06,
                    answer07: this.answer07,
                    answer08: this.answer08,
                    answer09: this.answer09,
                    answer10: this.answer10,
                    answer11: this.answer11,
                    etc01: this.etc01,
                    etc03: this.etc03,
                    etc07: this.etc07,
                    etc08: this.etc08,
                    etc09: this.etc09,
                }

                postSatisfaction(userData)
                    .then(() => {
                        alert('제출이 완료되었습니다.');

                        this.$emit('exit');
                    })
                    .catch(() => {
                        alert('이미 제출하였습니다.');

                        this.$emit('exit');
                    })
            }
        }
    },
}
</script>

<style scoped>

.bold_text {
    font-weight: bold;
}

.fa-times {
    color: black;
    font-size: 40px;
    position: absolute;
    right: 2%;
    top: 3%;
    cursor: pointer;
}

ul, li {
    list-style: none;
}
.satisfaction_box {
    width: 100%;
    height: 100vh;
    border: 1px solid gray;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10000;
}

.satisfaction_box::before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.5;
    background-color: black;
    z-index: -1;
}

.satisfaction_in_box {
    width: 95%;
    height: 90vh;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: calc(-90vh / 2);
    margin-left: calc(-95% / 2);
    text-align: center;
    padding: 30px;
    border-radius: 25px;
    overflow-y: scroll;
    background-color: white;
}

.satisfaction_tit {
    margin-bottom: 100px;
}

.satisfaction_sup, .satisfaction_problem {
    text-align: left;
}

.etc_inp {
    width: 800px;
    margin-left: 20px;
}

.satisfaction_sup {
    margin-bottom: 50px;
}

.satisfaction_problem {
    margin-bottom: 50px;
}

.satisfaction_problem h5 {
    margin-bottom: 30px;
}

.satisfaction_problem p {
    line-height: 15px;
    cursor: pointer;
    color: black;
}

.satisfaction_problem p span {
    margin: 0 15px;
}

.satisfaction_problem p span:first-child {
    margin-left: 0;

}

.table {
    border: 1px solid #e9ecef;
    font-size: 12px;
    text-align: center;
}

.table thead th {
    border: 1px solid #e9ecef;
}
.table tbody tr td {
    border: 1px solid #e9ecef !important;
    vertical-align: middle;
}

.table tbody tr td input[type="text"], .table tbody tr td input[type="number"] {
    width: 100%;
    border: 0;
    text-align: center;
}

textarea {
    width: 100%;
    height: 200px;
    resize: none;
}
</style>