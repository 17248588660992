import { createRouter, createWebHashHistory } from "vue-router";
import Dashboard from "@/views/Dashboard.vue";
import Tables from "@/views/Tables.vue";
import Project from "@/views/Projects.vue";
import Question from "@/views/Question.vue";
import Profile from "@/views/Profile.vue";
import SignIn from "@/views/SignIn.vue";
import SignUp from "@/views/SignUp.vue";
import store from "../store";

import { userReLogin } from '../api/index'
import { getCookie } from '../middlewares/index';

const routes = [
  {
    path: "/",
    name: "/",
    redirect: "/dashboard",
  },
  {
    path: "/dashboard",
    name: "대쉬보드",
    meta: { auth: true, admin: true },
    component: Dashboard,
  },
  {
    path: "/tables",
    name: "기관관리",
    meta: { auth: true },
    component: Tables,
  },
  {
    path: "/project",
    name: "통계",
    meta: { auth: true, admin: true},
    component: Project,
  },
  {
    path: "/question",
    name: "1:1문의관리",
    meta: { auth: true, admin: true},
    component: Question
  },
  {
    path: "/profile",
    name: "마이페이지",
    meta: { auth: true},
    component: Profile,
  },
  {
    path: "/sign-in",
    name: "Sign In",
    component: SignIn,
  },
  {
    path: "/sign-up",
    name: "Sign Up",
    component: SignUp,
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  linkActiveClass: "active",
});

router.beforeEach((to, from, next) => {
  const userEmail = localStorage.getItem('loginEmail');

  if (userEmail) {
    store.state.userEmail = userEmail;
  }

  store.state.token = getCookie();

  if(to.meta.auth) {
    if (store.state.token) {
      if (!store.state.loginUser) {
        userReLogin(store.state.token)
          .then(response => {
            store.state.loginUser = response.data;
    
            next();
          })
          .catch(err => {
            console.log(err);
          });
      } else {
        next();
      }
    } else {
      next("/sign-in");
    }
  } else {
    next();
  }

});

router.beforeEach((to, from, next) => {
  if (to.meta.admin) {
      if (store.state.loginUser.authority !== 9) {
        if (store.state.loginUser.authority !== 5) {
          alert('접근 권한이 없습니다.');

          next('/tables');
        }
      } else {
        next();
      }
  }

  next();
})

export default router;
