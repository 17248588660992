<template>
  <div class="w-auto h-auto collapse navbar-collapse max-height-vh-100 h-100" id="sidenav-collapse-main">
    <ul class="navbar-nav">
      <li class="nav-item">
        <sidenav-collapse navText="대쉬보드" :to="{ name: '대쉬보드' }" v-if="authority === 9 || authority === 5">
          <template #icon>
            <shop />
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item">
        <sidenav-collapse navText="기관관리" :to="{ name: '기관관리' }">
          <template #icon>
            <office />
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item">
        <sidenav-collapse navText="통계" :to="{ name: '통계' }" v-if="authority === 9 || authority === 5">
          <template #icon>
            <office />
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item">
        <sidenav-collapse navText="1:1 문의" :to="{ name: '1:1문의관리' }" v-if="authority === 9 || authority === 5">
          <template #icon>
            <office />
          </template>
        </sidenav-collapse>
      </li>
      <li class="mt-3 nav-item">
        <h6 class="text-xs ps-4 text-uppercase font-weight-bolder opacity-6"
          :class="this.$store.state.isRTL ? 'me-4' : 'ms-2'">
          페이지
        </h6>
      </li>
      <li class="nav-item">
        <sidenav-collapse navText="마이페이지" :to="{ name: '마이페이지' }">
          <template #icon>
            <customer-support />
          </template>
        </sidenav-collapse>
      </li>
    </ul>
  </div>
</template>
<script>
import SidenavCollapse from "./SidenavCollapse.vue";
import Shop from "../../components/Icon/Shop.vue";
import Office from "../../components/Icon/Office.vue";
import CustomerSupport from "../../components/Icon/CustomerSupport.vue";

export default {
  name: "SidenavList",
  props: {
    cardBg: String,
  },
  data() {
    return {
      title: "기업채용 현장지원",
      controls: "dashboardsExamples",
      isActive: "active",
      authority: ''
    };
  },
  created () {
    setTimeout(() => {
      this.authority = this.$store.state.loginUser.authority;
    }, 500);
  },
  components: {
    SidenavCollapse,
    Shop,
    Office,
    CustomerSupport,
  },
  methods: {
    getRoute() {
      const routeArr = this.$route.path.split("/");
      return routeArr[1];
    },
  },
};
</script>
