<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4">
        <mini-statistics-card title="컨설팅 요청 내역"
          :value="`${this.$store.state.user ? this.$store.state.user.length : 0} 개`" :percentage="{
            value: '+505%',
            color: 'text-success',
          }" :icon="{
            component: 'ni ni-money-coins',
            background: iconBackground,
          }" direction-reverse />
      </div>
      <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4">
        <mini-statistics-card title="1:1 문의 내역"
          :value="`${this.$store.state.qnaData ? this.$store.state.qnaData.length : 0} 개`" :percentage="{
            value: '+3%',
            color: 'text-success',
          }" :icon="{
            component: ' ni ni-world',
            background: iconBackground,
          }" direction-reverse />
      </div>
    </div>
    <div class="row">
      <div class="col-lg-7 mb-lg-0 mb-4">
        <div class="card">
          <div class="card-body p-3">
            <div class="row">
              <div class="col-lg-6">
                <div class="d-flex flex-column h-100">
                  <p class="mb-1 pt-2 text-bold">기관 관리</p>
                  <h5 class="font-weight-bolder">총 {{ this.$store.state.user ? this.$store.state.user.length : 0 }}개의
                    컨설팅
                    요청 내역이 있습니다.</h5>
                  <p class="mb-5">
                    승인: {{ stateA }}개<br>
                    거절: {{ stateR }}개<br>
                    대기: {{ stateS }}개
                  </p>
                  <router-link to="/tables" class="text-body text-sm font-weight-bold mb-0 icon-move-right mt-auto">
                    기관 관리 바로가기
                    <i class="fas fa-arrow-right text-sm ms-1" aria-hidden="true"></i>
                  </router-link>
                </div>
              </div>
              <div class="col-lg-5 ms-auto text-center mt-5 mt-lg-0">
                <div class="bg-gradient-success border-radius-lg h-100">
                  <img src="../assets/img/shapes/waves-white.svg"
                    class="position-absolute h-100 w-50 top-0 d-lg-block d-none" alt="waves" />
                  <div class="position-relative d-flex align-items-center justify-content-center h-100">
                    <img class="w-100 position-relative z-index-2 pt-4"
                      src="../assets/img/illustrations/rocket-white.png" alt="rocket" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-5">
        <div class="card h-100 p-3">
          <div class="overflow-hidden position-relative border-radius-lg bg-cover h-100" style="
              background-image: url('https://demos.creative-tim.com/soft-ui-dashboard/assets/img/ivancik.jpg');
            ">
            <span class="mask bg-gradient-dark"></span>
            <div class="card-body position-relative z-index-1 p-3 h-100">
              <div class="d-flex flex-column h-100">
                <h5 class="text-white font-weight-bolder mb-4 pt-2">
                  통계
                </h5>
                <p class="text-white mb-5">
                  기관 통계를 확인 할 수 있습니다.
                </p>
                <router-link to="/project" class="text-white font-weight-bold ps-1 mb-0 icon-move-left mt-auto">
                  통계 바로가기
                  <i class="fas fa-arrow-right text-sm ms-1" aria-hidden="true"></i>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-4 row">
      <div class="col-lg-7" style="width: 100%;">
        <!-- line chart -->
        <div class="card z-index-2">
          <gradient-line-chart id="chart-line" title="기업채용 현장지원 그래프" description="<i class='fa fa-arrow-up text-success'></i>
      수익률 <span class='font-weight-bold'>4% 상승</span>" :chart="{
        labels: [
          '1월',
          '2월',
          '3월',
          '4월',
          '5월',
          '6월',
          '7월',
          '8월',
          '9월',
          '10월',
          '11월',
          '12월',
        ],
        datasets: [
          {
            label: 'Mobile Apps',
            data: [50, 40, 300, 220, 500, 250, 400, 230, 500, 600, 700, 800],
          },
          {
            label: 'Websites',
            data: [30, 90, 40, 140, 290, 290, 340, 230, 400, 500, 600, 700],
          },
        ],
      }" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getUser, getProfile, getQnA } from '../api/index'

import MiniStatisticsCard from "@/examples/Cards/MiniStatisticsCard.vue";
import GradientLineChart from "@/examples/Charts/GradientLineChart.vue";
import US from "../assets/img/icons/flags/US.png";
import DE from "../assets/img/icons/flags/DE.png";
import GB from "../assets/img/icons/flags/GB.png";
import BR from "../assets/img/icons/flags/BR.png";
import {
  faHandPointer,
  faUsers,
  faCreditCard,
  faScrewdriverWrench,
} from "@fortawesome/free-solid-svg-icons";
export default {
  name: "dashboard-default",
  data() {
    return {
      iconBackground: "bg-gradient-success",
      faCreditCard,
      faScrewdriverWrench,
      faUsers,
      faHandPointer,
      sales: {
        us: {
          country: "United States",
          sales: 2500,
          value: "$230,900",
          bounce: "29.9%",
          flag: US,
        },
        germany: {
          country: "Germany",
          sales: "3.900",
          value: "$440,000",
          bounce: "40.22%",
          flag: DE,
        },
        britain: {
          country: "Great Britain",
          sales: "1.400",
          value: "$190,700",
          bounce: "23.44%",
          flag: GB,
        },
        brasil: {
          country: "Brasil",
          sales: "562",
          value: "$143,960",
          bounce: "32.14%",
          flag: BR,
        },
      },
      stateA: 0,
      stateR: 0,
      stateS: 0
    };
  },
  components: {
    MiniStatisticsCard,
    GradientLineChart,
  },
  mounted () {
    // 기관 정보 불러오기
    const userData = {
      "token": this.$store.state.token,
    }

    const profileData = {
      "email": this.$store.state.userEmail,
      "token": this.$store.state.token,
    }

    getUser(userData)
      .then(response => {
        this.$store.state.user = response.data;

        this.stateLenth();
      })
      .catch(err => {
        console.log(err);
      });

    getQnA(userData)
      .then(response => {
        this.$store.state.qnaData = response.data;
      })
      .catch(err => {
        console.log(err);
      })

    getProfile(profileData)
      .then(response => {
        this.$store.state.myprofile = response.data;
      })
      .catch(err => {
        console.log(err);
      });
  },
  methods: {
    stateLenth() {
      for (let user of this.$store.state.user) {
        if (user.appstate === 'A') {
          this.stateA = this.stateA + 1;
        } else if (user.appstate === 'R') {
          this.stateR = this.stateR + 1;
        } else if (user.appstate === 'S') {
          this.stateS = this.stateS + 1;
        } 
      }
    }
  },
};
</script>
<style scoped>
.col-xl-3 {
  width: 50%;
}
</style>